import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Typography } from 'amphitheatre';

import { RootState } from '../../../Redux/Reducers';
import Column, { CartLine } from './BuyColumn.styled';

const { Header } = Typography;

function mapStateToProps(state: RootState) {
  const { plans } = state.license;
  return { plans };
}

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector> &
  React.HTMLAttributes<HTMLDivElement> & {
    /** Selected plan id */
    selectedPlan: string;
  };

function BuyColumn({ children, plans, selectedPlan }: Props) {
  let priceDisplay;

  if (plans && plans.length) {
    const license = plans.find((obj) => obj.id === selectedPlan);
    if (license) {
      priceDisplay = (
        <>
          <CartLine>
            <div>{license.name}</div>
            <div>${(parseFloat(license.price) * 0.9).toFixed(2)} AUD</div>
          </CartLine>
          <CartLine>
            <div>GST</div>
            <div>${(parseFloat(license.price) * 0.1).toFixed(2)} AUD</div>
          </CartLine>
          <hr />
          <CartLine>
            <div style={{ fontWeight: 'bold' }}>Total Price</div>
            <div style={{ fontWeight: 'bold' }}>${license.price} AUD</div>
          </CartLine>
        </>
      );
    }
  }

  return (
    <Column>
      <div className="uk-text-center">
        <Header level={2}>Purchase Summary</Header>
      </div>
      {priceDisplay}
      <div style={{ padding: '0.5em' }} />
      {children}
    </Column>
  );
}

BuyColumn.defaultProps = {
  selectedPlan: 'pigi_casual_3month',
};

export default connector(BuyColumn);
