import { combineReducers } from '@reduxjs/toolkit';

import { default as user } from './user';
import { default as darkMode } from './darkMode';
import { default as license } from './license';
import { default as licenseFilter } from './licenseFilter';
import { default as tokens } from './tokens';
import { default as tokenHistory } from './tokenHistory';

const rootReducer = combineReducers({
  user,
  darkMode,
  license,
  licenseFilter,
  tokens,
  tokenHistory,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
