import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState = true;

const darkMode = createSlice({
  name: 'darkMode',
  initialState,
  reducers: {
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      state = payload;
      return state;
    },
    toggleDarkMode: (state) => {
      state = !state;
      return state;
    },
  },
});

export const { setDarkMode, toggleDarkMode } = darkMode.actions;

export default darkMode.reducer;
