import React from 'react';
import { Dropin } from 'braintree-web-drop-in';
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { withTheme, DefaultTheme } from 'styled-components';
import { connect, ConnectedProps } from 'react-redux';

import { Button, Loader, Typography } from 'amphitheatre';

import {
  getBraintreeToken,
  getMyPurchases,
  toastError,
  assertIsError,
} from 'src/Utils';
import { setBrainTreeToken } from 'src/Redux/Reducers/user';
import { RootState } from 'src/Redux/Reducers';

import BuyColumn from '../BuyColumn';
import ButtonStyle, { ButtonText } from '../PurchaseForm/PurchaseForm.styled';
import BrainTreeWrapper, { AnotherWrapper } from './Braintree.styled';
import DropIn from 'src/Components/BraintreeDropin';

import checkout from './Braintree.actions';

const { Header } = Typography;

function mapStateToProps(state: RootState) {
  const { brainTreeToken } = state.user;
  const { darkMode } = state;

  return { brainTreeToken, darkMode };
}

const connector = connect(mapStateToProps, {
  _setBrainTreeToken: setBrainTreeToken,
});

type Props = ConnectedProps<typeof connector> & {
  /* The data prepared to send to api after braintree info is used */
  purchaseData?: Record<string, unknown>;

  /* Informs the parent to go to the previous page */
  goback: (event: React.MouseEvent<HTMLButtonElement>) => void;

  /* Informs the parent to go to the next page  */
  proceed: () => void;

  theme: DefaultTheme;
};

export interface State {
  nonce: string | null;
  processing: boolean;
}

class Braintree extends React.Component<Props, State> {
  instance: Dropin | undefined;

  state: State = {
    nonce: null,
    processing: false,
  };

  componentDidMount = () => {
    getBraintreeToken();
  };

  componentDidUpdate = (prevProps: Props) => {
    const { _setBrainTreeToken, darkMode } = this.props;

    if (prevProps.darkMode !== darkMode) {
      _setBrainTreeToken(null);
      getBraintreeToken();
    }
  };

  componentWillUnmount = () => {
    const { _setBrainTreeToken } = this.props;
    _setBrainTreeToken(null);
  };

  buy = async () => {
    const { purchaseData, proceed } = this.props;
    const { nonce } = this.state;

    const billingData: Record<string, unknown> = {
      ...purchaseData,
      nonce,
    };

    if (!purchaseData) {
      toastError(
        'No form data has been recorded. Please go back to step "Enter Details" and resubmit your data.',
      );
      return;
    }

    this.setState({ processing: true });

    try {
      await checkout(billingData);
      getMyPurchases();
      proceed();
    } catch (error) {
      assertIsError(error);
      toastError(error.message);
      this.setState({ processing: false });
    }
  };

  setCard = async () => {
    if (this.instance) {
      const { nonce } = await this.instance.requestPaymentMethod();

      this.setState({ nonce });
    }
  };

  switchPayment = () => {
    const { nonce } = this.state;

    if (nonce) {
      this.setState({ nonce: null });
    }
  };

  render() {
    const { brainTreeToken, purchaseData, goback, theme } = this.props;
    const { nonce, processing } = this.state;

    let continueBtn = (
      <Button disabled={!brainTreeToken} onClick={this.setCard}>
        <ButtonText>
          Confirm Details
          <FiChevronsRight />
        </ButtonText>
      </Button>
    );

    if (nonce) {
      continueBtn = (
        <Button loading={processing} onClick={this.buy}>
          <ButtonText>
            Confirm Purchase
            <FiChevronsRight />
          </ButtonText>
        </Button>
      );
    }

    let content = (
      <div className="uk-text-center">
        <Loader message="Loading Braintree ..." />
      </div>
    );

    if (processing) {
      content = (
        <div className="uk-text-center">
          <Loader message="Processing payment, please do not leave this page ..." />
        </div>
      );
    } else if (brainTreeToken) {
      content = (
        <AnotherWrapper>
          <BuyColumn
            selectedPlan={
              purchaseData
                ? (purchaseData.plan_id as string)
                : 'pigi_casual_3month'
            }
          />
          <BrainTreeWrapper>
            <DropIn
              options={{
                authorization: brainTreeToken,
                card: {
                  overrides: {
                    styles: {
                      input: {
                        color: theme.fontColour,
                      },
                      'input:focus': {
                        color: theme.fontColour,
                      },
                    },
                  },
                },
                paypal: {
                  flow: 'vault',
                },
              }}
              onInstance={(instance) => {
                this.instance = instance;
                this.instance.on('paymentOptionSelected', this.switchPayment);
              }}
            />
            <ButtonStyle className="uk-margin">
              {continueBtn}
              <Button ghost onClick={goback} disabled={processing}>
                <ButtonText>
                  <FiChevronsLeft />
                  Go Back
                </ButtonText>
              </Button>
            </ButtonStyle>
          </BrainTreeWrapper>
        </AnotherWrapper>
      );
    }

    return (
      <div>
        <Header level={2}>Enter your payment details</Header>
        <div className="uk-margin">{content}</div>
      </div>
    );
  }
}

export default connector(withTheme(Braintree));
