import styled from 'styled-components';

export const Token = styled.div`
  color: ${({ theme }) => theme.fontColour};
  display: flex;
  align-items: center;
  gap: 0.75rem;
  position: relative;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  font-size: 2rem;
`;

export const Content = styled.div`
  flex-grow: 1;
`;

export const Expired = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  font-family: ${({ theme }) => theme.fontHeader};
  color: ${({ theme }) => theme.error400};
`;

export const ExpiresIn = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-flow: column;
  font-family: ${({ theme }) => theme.fontHeader};
`;

export const Expiry = styled.div``;

export const ExpiryTime = styled.div`
  font-size: 14px;
`;

export const Name = styled.div`
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 16px;
`;

export const Location = styled.div`
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 14px;
`;

export const Button = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.fontColour};
  font-size: 24px;
  padding: 0;

  &:hover {
    color: ${({ theme }) => theme.grey500};
    cursor: pointer;
  }
`;

export const Dropdown = styled.div`
  background-color: ${({ theme }) => theme.grey700};
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 3;

  ul {
    font-family: ${({ theme }) => theme.fontHeader};
    list-style: none;
    margin: 0;
    padding: 0.75rem;

    li {
      padding: 0.5rem;
    }

    button {
      background: none;
      border: none;
      color: ${({ theme }) => theme.white};
      cursor: pointer;
      padding: 0;
      text-align: left;
      width: 100%;

      &:hover {
        color: ${({ theme }) => theme.grey300};
      }
    }
  }

  display: none;
  transition: 250ms;

  &.dropdown-enter {
    display: block;
    opacity: 0;
  }

  &.dropdown-enter-active {
    display: block;
    opacity: 1;
  }

  &.dropdown-enter-done {
    display: block;
  }

  &.dropdown-exit {
    display: block;
    opacity: 1;
  }

  &.dropdown-exit-active {
    display: block;
    opacity: 0;
  }
`;
