import React, { useState } from 'react';
import { Button, Form, Typography } from 'amphitheatre';
import { useLocation } from 'react-router-dom';

import SuccessDisplay from './Success.display';
import callChange from './ResetPassword.action';
import { toastError, assertIsError } from 'src/Utils';

function ResetPassword(): React.ReactElement {
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [success, setSuccess] = useState(false);
  const location = useLocation();
  const token = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1,
  );

  const resetPassword = async (data: Record<string, unknown>) => {
    const { newPassword, newPasswordConfirm } = data as {
      newPassword: string;
      newPasswordConfirm: string;
    };

    setLoading(true);

    try {
      if (newPassword === newPasswordConfirm) {
        await callChange(newPassword, newPasswordConfirm, token);
        setSuccess(true);
      }
    } catch (error) {
      assertIsError(error);
      toastError(error.message);
    }

    setLoading(false);
  };

  if (success) {
    return <SuccessDisplay />;
  }

  return (
    <div>
      <Typography>Password</Typography>
      <div style={{ padding: '0.5em' }} />
      <Form onSubmit={resetPassword} clearOnSubmit>
        <Form.Validator
          name="newPassword"
          type="password"
          peek={(_, value) => setNewPassword(value)}
          validators={[
            {
              validator: (value) =>
                !value || !newPasswordConfirm || value === newPasswordConfirm,
              errorMessage: 'Passwords do not match',
            },
          ]}
          required
        >
          <Form.Input label="New Password" />
        </Form.Validator>
        <Form.Validator
          name="newPasswordConfirm"
          type="password"
          peek={(_, value) => setNewPasswordConfirm(value)}
          validators={[
            {
              validator: (value) =>
                !value || !newPassword || value === newPassword,
              errorMessage: 'Passwords do not match',
            },
          ]}
          required
        >
          <Form.Input label="Confirm New Password" />
        </Form.Validator>
        <Button type="submit" loading={loading} fill>
          Update Password
        </Button>
        <div className="uk-margin" />
      </Form>
    </div>
  );
}

export default ResetPassword;
