import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Form, Typography } from 'amphitheatre';

import { toastError, assertIsError } from 'src/Utils';
import { callLoginApi, UnconfirmedError } from './Login.action';

import { addAccessToken, preserveToken } from '../../Redux/Reducers/tokens';

const { Header } = Typography;

const connector = connect(undefined, {
  _addAccessToken: addAccessToken,
  _preserveToken: preserveToken,
});

type LoginProps = ConnectedProps<typeof connector>;

export function Login({
  _addAccessToken,
  _preserveToken,
}: LoginProps): React.ReactElement {
  const [loading, setLoading] = useState(false);
  const [unconfirmed, setUnconfirmed] = useState(false);

  const onLogin = async (data: Record<string, unknown>) => {
    setLoading(true);

    try {
      const { preserveToken } = data;
      const accessToken = await callLoginApi(data);
      _preserveToken(preserveToken === 'true');
      _addAccessToken(accessToken);
    } catch (error) {
      setLoading(false);
      if (error instanceof UnconfirmedError) {
        setUnconfirmed(true);
      } else {
        assertIsError(error);
        toastError(error.message);
      }
    }
  };

  if (unconfirmed) {
    return <Redirect to="/resend_confirmation" />;
  } else {
    return (
      <div className="uk-margin-top">
        <Form onSubmit={onLogin} id={'Login Form'}>
          <span className="uk-text-center">
            <Header level={1}>Sign in</Header>
          </span>
          <Form.Validator name="username" required>
            <Form.Input
              label="Username"
              aria-label="Username"
              data-testid="username"
            />
          </Form.Validator>
          <Form.Validator name="password" type="password" required>
            <Form.Input
              label="Password"
              aria-label="Password"
              data-testid="password"
            />
          </Form.Validator>
          <Form.Validator name="preserveToken" type="checkbox">
            <Form.Checkbox
              label="Stay signed in?"
              aria-label="Preserve Token"
              data-testid="preserve-token"
            />
          </Form.Validator>
          <Button
            type="submit"
            fill
            loading={loading}
            data-testid="submit-button"
          >
            Sign in
          </Button>
        </Form>
        <div className="uk-margin">
          <Link to="/register">
            <Button ghost fill disabled={loading}>
              Register
            </Button>
          </Link>
        </div>
        <div className="uk-margin">
          <Link to="/reset">
            <Button link fill disabled={loading}>
              Forgot Password
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default connector(Login);
