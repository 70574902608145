import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-flow: column;

  button:first-child {
    margin-bottom: 1.5em;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-flow: row-reverse;

  #form {
    flex-grow: 1;
  }

  #billingPostalCode {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    &[type='number'] {
      -moz-appearance: textfield; /* Firefox */
    }
  }
`;

export const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
