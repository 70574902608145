/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import moment from 'moment';
import AnimateHeight from 'react-animate-height';
import { Button, Table } from 'amphitheatre';
import callGetReceipts from './License.action';
import {
  FaCalendarAlt,
  FaCreditCard,
  FaTimes,
  FaReceipt,
} from 'react-icons/fa';

import {
  ActionBlock,
  Block,
  Content,
  Icon,
  IconText,
  StyledLicense,
  Title,
} from './License.styled';

import { Invoice, Props } from './License.types';
import { toastError, assertIsError } from 'src/Utils';
import { Link } from 'react-router-dom';

const columns = [
  {
    id: 'periodStart',
    text: 'Period Start',
  },
  {
    id: 'periodEnd',
    text: 'Period End',
  },
  {
    id: 'account',
    text: 'Account',
  },
  {
    id: 'receipts',
    text: (
      <>
        <FaReceipt />
        &nbsp; Invoice
      </>
    ),
  },
];

/**
 * Component used to display information relating to a license
 *
 *
 * @param {string} id Id of the license agreement
 * @param {boolean} cancelled
 * @param {boolean} expired
 * @param {string} status
 * @param {string} className
 * @param {string} accessToken A valid access token given to the user
 * @param {string} title Name of the license
 * @param {string} purchaseDate
 * @param {string} billingDate
 * @param {string} billingAmount
 * @param {string} expiryDate
 * @param {(id: string) => void} cancel Function callback when user wants to cancel the license
 *
 * Also allows for other div props
 */
function Subscription(props: Props): JSX.Element {
  const {
    id,
    cancelled,
    expired,
    className,
    accessToken,
    title,
    purchaseDate,
    billingDate,
    billingAmount,
    expiryDate,
    status,
    cancel,
    ...divProps
  } = props;

  const [showReceipt, setShowReceipt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receipts, setReceipts] = useState<Array<Invoice> | null>(null);

  const bill = `$${billingAmount} AUD`;
  const parsedBillingDate = moment(billingDate).format('Do MMMM YYYY');
  const parsedPurchaseDate = moment(purchaseDate).format('Do MMMM YYYY');
  const parsedExpiryDate = moment(expiryDate).format('Do MMMM YYYY');

  const getReceipts = async () => {
    setLoading(true);
    try {
      const res = await callGetReceipts(id);
      setReceipts(res);
      setShowReceipt(true);
    } catch (error) {
      setLoading(false);
      assertIsError(error);
      toastError(error.message);
    }
  };

  const toggleReceipt = () => setShowReceipt(!showReceipt);

  const onCancel = () => {
    if (cancel) {
      cancel(id);
    }
  };

  const data = receipts?.map((receipt, index) => ({
    id: index,
    periodStart: moment(receipt.period_start).format('Do MMMM YYYY'),
    periodEnd: moment(receipt.period_end).format('Do MMMM YYYY'),
    account: `$${parseFloat(receipt.gst_excluded).toFixed(2)} + $${parseFloat(
      receipt.gst,
    ).toFixed(2)} (GST) = $${parseFloat(receipt.amount).toFixed(2)} AUD`,
    receipts: (
      <>
        <Link to={`/invoice/${receipt.id}`} rel="noopener noreferrer">
          <Button link style={{ padding: 0 }}>
            Invoice
          </Button>
        </Link>
      </>
    ),
  }));

  const BtnReceipt = (
    <Button
      loading={loading}
      disabled={loading}
      fill
      onClick={receipts ? toggleReceipt : getReceipts}
    >
      {showReceipt ? 'Close' : 'View Receipts'}
    </Button>
  );

  let buttons = (
    <>
      {BtnReceipt}
      <Button fill ghost style={{ marginTop: '1em' }} onClick={onCancel}>
        Cancel
      </Button>
    </>
  );

  if (cancelled || expired) {
    buttons = BtnReceipt;
  }

  const inactiveInfo = (
    <>
      <Block style={{ flexBasis: '18%' }}>
        <Icon>
          <FaCalendarAlt />
        </Icon>
        <IconText>
          <span className="title">Expiry Date</span>
          <span className="content">{parsedExpiryDate}</span>
        </IconText>
      </Block>
      <Block style={{ flexBasis: '20%' }}>
        <Icon>
          <FaTimes />
        </Icon>
        <IconText>
          <span className="title">Status</span>
          <span className="content">{status}</span>
        </IconText>
      </Block>
    </>
  );

  let billingInfo = (
    <>
      <Block style={{ flexBasis: '18%' }}>
        <Icon>
          <FaCreditCard />
        </Icon>
        <IconText>
          <span className="title">Next Billing Date</span>
          <span className="content">{parsedBillingDate}</span>
        </IconText>
      </Block>
      <Block style={{ flexBasis: '14%' }}>
        <IconText>
          <span className="title">Next Billing Amount</span>
          <span className="content">{bill}</span>
        </IconText>
      </Block>
    </>
  );

  if (cancelled || expired) {
    billingInfo = inactiveInfo;
  }

  return (
    <StyledLicense
      id={id}
      className={`${className || ''} ${expired ? 'expired' : ''}`}
      {...divProps}
    >
      <Content>
        <Block style={{ flexBasis: '20%' }}>
          <Title>{title}</Title>
        </Block>
        <Block style={{ flexBasis: '20%' }}>
          <Icon>
            <FaCalendarAlt />
          </Icon>
          <IconText>
            <span className="title">Purchased Date</span>
            <span className="content">{parsedPurchaseDate}</span>
          </IconText>
        </Block>
        {billingInfo}
        <ActionBlock style={{ flexBasis: 0, flexGrow: 1 }}>
          {buttons}
        </ActionBlock>
      </Content>
      <AnimateHeight duration={250} height={showReceipt ? 'auto' : 0}>
        <Content>
          <Table
            columns={columns}
            data={data || []}
            style={{ background: '#004c85', overflowX: 'auto', width: '100%' }}
            data-testid={`invoice-${id}`}
          />
        </Content>
      </AnimateHeight>
    </StyledLicense>
  );
}

export default Subscription;
