import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/Redux/Reducers';
import { toastSuccess, toastError, assertIsError } from 'src/Utils';
import { changeUsername } from './Users.actions';
import { Button, Form, Typography } from 'amphitheatre';
import checkUsernameApi from '../../Register/CheckUsername.action';

const { EditText } = Typography;

function mapStateToProps(state: RootState) {
  const { name } = state.user;

  return {
    defaultValue: name,
  };
}

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector>;

function ChangeUsername({ defaultValue }: Props) {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const onSubmit = async (data: Record<string, unknown>) => {
    const { newUsername } = data as {
      newUsername: string;
    };
    setLoading(true);

    try {
      const message = await changeUsername(newUsername);
      toastSuccess(message);
      setEdit(false);
    } catch (error) {
      assertIsError(error);
      toastError(error.message);
    }
    setLoading(false);
  };

  const toggleEdit = () => setEdit(!edit);

  return (
    <div>
      <Typography>Username</Typography>
      <EditText onClick={toggleEdit}>{defaultValue}</EditText>
      <AnimateHeight duration={250} height={edit ? 'auto' : 0}>
        <div style={{ padding: '0.5em' }} />
        <Form onSubmit={onSubmit} clearOnSubmit>
          <Form.Validator
            name="newUsername"
            validators={[
              {
                validator: (name) => !disabled,
                errorMessage: 'Please enter a different username.',
              },
              {
                validator: async (name) => await checkUsernameApi(name),
                errorMessage: 'Username already exists.',
              },
            ]}
            required
          >
            <Form.Input
              label="New Username"
              onChangeCapture={(e) =>
                setDisabled(
                  defaultValue === (e.target as HTMLTextAreaElement).value,
                )
              }
              showErrorOnChange
              showLoadingIcon
            />
          </Form.Validator>
          <Button type="submit" disabled={disabled} loading={loading} fill>
            Update Username
          </Button>
          <div className="uk-margin">
            <Button disabled={loading} onClick={toggleEdit} ghost fill>
              Cancel
            </Button>
          </div>
        </Form>
      </AnimateHeight>
    </div>
  );
}

export default connector(ChangeUsername);
