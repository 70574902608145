import React from 'react';
import { Link } from 'react-router-dom';

import { FiCheck } from 'react-icons/fi';
import { Button, Typography } from 'amphitheatre';

import { SuccessText } from '../App/App.styled';

const { Paragraph } = Typography;

interface Props {
  /** What to display to the user */
  email: string;

  /** Resends the email password if the user so desires */
  resetPassword: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

function SuccessDisplay({ email, resetPassword }: Props): React.ReactElement {
  return (
    <div className="uk-container uk-text-center uk-padding">
      <SuccessText>
        <FiCheck />
      </SuccessText>
      <Paragraph>We&apos;ve just emailed you a link at {email}</Paragraph>
      <Paragraph>Please check your email and click the secure link.</Paragraph>
      <Paragraph className="uk-text-bolder">
        The link sent to you will expire in 10 minutes.
      </Paragraph>
      <Paragraph>
        If you don&apos;t see our email, please check your spam folder.
      </Paragraph>
      <div className="uk-margin">
        <Button ghost fill onClick={resetPassword}>
          Resend Email
        </Button>
      </div>
      <div className="uk-margin">
        <Link to="/login">
          <Button fill>Return to login</Button>
        </Link>
      </div>
    </div>
  );
}

export default SuccessDisplay;
