import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Form, Typography } from 'amphitheatre';

import { RootState } from 'src/Redux/Reducers';
import {
  toastSuccess,
  toastError,
  validateEmail,
  assertIsError,
} from 'src/Utils';
import { changeEmail } from './Users.actions';

const { EditText } = Typography;

function mapStateToProps(state: RootState) {
  const { email } = state.user;
  return {
    defaultValue: email,
  };
}

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector>;

function ChangeEmail(props: Props) {
  const { defaultValue } = props;
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const onSubmit = async (data: Record<string, unknown>) => {
    const { email } = data as {
      email: string;
    };
    setLoading(true);
    try {
      const message = await changeEmail(email);
      toastSuccess(message);
      setEdit(false);
    } catch (error) {
      assertIsError(error);
      toastError(error.message);
    }
    setLoading(false);
  };

  const toggleEdit = () => setEdit(!edit);

  return (
    <div>
      <Typography>Email</Typography>
      <EditText onClick={toggleEdit}>{defaultValue}</EditText>
      <AnimateHeight duration={250} height={edit ? 'auto' : 0}>
        <div style={{ padding: '0.5em' }} />
        <Form onSubmit={onSubmit} clearOnSubmit>
          <Form.Validator
            name="email"
            validators={[
              {
                validator: (value) => validateEmail(value),
                errorMessage: 'Email is not a valid format.',
              },
              {
                validator: (name) => !disabled,
                errorMessage: 'Please enter a different email.',
              },
            ]}
            required
          >
            <Form.Input
              label="New Email"
              onChangeCapture={(e) =>
                setDisabled(
                  defaultValue === (e.target as HTMLTextAreaElement).value,
                )
              }
              showErrorOnChange
              showLoadingIcon
            />
          </Form.Validator>
          <Button type="submit" disabled={disabled} loading={loading} fill>
            Update Email
          </Button>
          <div className="uk-margin">
            <Button disabled={loading} onClick={toggleEdit} ghost fill>
              Cancel
            </Button>
          </div>
        </Form>
      </AnimateHeight>
    </div>
  );
}

export default connector(ChangeEmail);
