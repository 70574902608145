export { toastSuccess, toastError } from './toast';

export { validateEmail, validateNumber } from './validators';

export {
  getIsEnterpriseMember,
  getHasInvites,
  getUserDetail,
  getPlans,
  getMyPurchases,
  getTokenHistory,
  clearMyPurchases,
  clearData,
} from './saber';

export {
  refreshAccessToken,
  getBraintreeToken,
  checkAccessToken,
  getAccessExpiry,
} from './tokens';

export type {
  Account,
  AccountMembership,
  Membership,
  Memberships,
  Invite,
  Invites,
} from './types';

export function assertIsError(error: unknown): asserts error is Error {
  // if you have nodejs assert:
  // assert(error instanceof Error);
  // otherwise
  if (!(error instanceof Error)) {
    throw error;
  }
}
