import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Token {
  device: string;
  expires: string;
  ips: string;
  jti: string;
  revoked: string;
  type: string;
}

interface State {
  tokens: Array<Token> | null;
}

export const initialState: State = {
  tokens: null,
};

const tokenHistorySlice = createSlice({
  name: 'tokenHistory',
  initialState,
  reducers: {
    setTokens: (state, action: PayloadAction<Array<Token> | null>) => {
      const { payload } = action;
      state.tokens = payload;
    },
  },
});

export const { setTokens } = tokenHistorySlice.actions;

export default tokenHistorySlice.reducer;
