/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import StyledSwitch from './ToggleSwitch.styled';

interface Props {
  ariaLabel?: string;
  checked: boolean;
  onToggle?: (checked: boolean) => void;
}

function ToggleSwitch({
  ariaLabel,
  checked,
  onToggle,
}: Props): React.ReactElement {
  const onClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (onToggle) {
      onToggle(!checked);
    }
  };

  return (
    <StyledSwitch className="sa-switch">
      <input
        type="checkbox"
        checked={checked}
        onClick={onClick}
        aria-label={ariaLabel}
        readOnly
      />
      <span className="slider round" />
    </StyledSwitch>
  );
}

ToggleSwitch.defaultProps = {
  checked: false,
};

export default ToggleSwitch;
