import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Typography } from 'amphitheatre';

import SuccessDisplay from './Success.display';
import callReset from './ForgotPassword.action';
import { toastError, validateEmail, assertIsError } from 'src/Utils';

const { Header } = Typography;

function ForgotPassword(): React.ReactElement {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);

  const resetPassword = async (data: Record<string, unknown>) => {
    const { email: emailData } = data as { email: string };

    setEmail(emailData);
    setSubmitted(true);

    try {
      await callReset(emailData);
      setSuccess(true);
    } catch (err) {
      assertIsError(err);
      toastError(err.message);
      setSubmitted(false);
    }
  };

  const resetAgain = async () =>
    await callReset(email).catch((err) => {
      assertIsError(err);
      toastError(err.message);
    });

  if (success) {
    return <SuccessDisplay email={email} resetPassword={resetAgain} />;
  }

  return (
    <div className="uk-margin-top">
      <Form onSubmit={resetPassword}>
        <span className="uk-text-center">
          <Header level={2}>Reset Password</Header>
        </span>
        <Form.Validator
          name="email"
          validators={[
            {
              validator: (value) => validateEmail(value),
              errorMessage: 'Email is not a valid format.',
            },
          ]}
          required
        >
          <Form.Input label="Email" />
        </Form.Validator>
        <Button type="submit" fill loading={submitted}>
          Reset Password
        </Button>
      </Form>
      <div className="uk-margin">
        <Link to="/login">
          <Button ghost fill disabled={submitted}>
            Return to login
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default ForgotPassword;
