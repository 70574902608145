import { toast } from 'react-toastify';

export function toastSuccess(message: string): void {
  // Outsource the calling of the toast functions to prevent code bloat
  toast.success(message, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function toastError(message: string): void {
  // Outsource the calling of the toast functions to prevent code bloat
  toast.error(message, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}
