import React from 'react';

import { StyledContainer } from './Container.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /** Toggle the border around the container */
  border?: boolean;

  /** How big should the container be? (lg: '1160px', md: '768px', sm: '456px', xs: '380px',) */
  size: string;
}

const Container: React.FC<Props> = ({
  border,
  children,
  className,
  size,
  ...otherProps
}: Props) => {
  return (
    <StyledContainer
      className={`${className || ''} ${border ? 'border' : ''}`}
      size={size}
      {...otherProps}
    >
      {children}
    </StyledContainer>
  );
};
Container.defaultProps = {
  border: false,
  size: 'lg',
};

export default Container;
