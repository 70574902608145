import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from 'amphitheatre';

import * as Hero from 'src/page-styles/hero.styled';
import * as Base from 'src/page-styles/base.styled';
import { iamClient } from 'src/clients';

function ConfirmAccount(): React.ReactElement {
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const location = useLocation();
  const token = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1,
  );

  useEffect(() => {
    iamClient
      .post('/users/confirm', { token })
      .then((res) => {
        setMessage('Your account has been confirmed.');
        setSuccess(true);
      })
      .catch((error) => {
        setMessage(
          error.response?.data?.msg ??
            'Unexpected error. Please try again or contact us.',
        );
        setSuccess(false);
      });
  }, [token]);

  let displayElement = <Loader message="Loading..." />;

  if (success) {
    displayElement = (
      <div>
        <Base.Heading>Thank you, your Saber Account is confirmed.</Base.Heading>
        <Base.Paragraph>{message}</Base.Paragraph>
        <Base.Paragraph>
          To subscribe to Saber's paid services, sign in with the button above
          or by clicking{' '}
          <a href={`/`} rel="noopener noreferrer" target="_blank">
            HERE
          </a>
          .
        </Base.Paragraph>
        <Base.Paragraph>
          To access TAROT immediately for free, click{' '}
          <a
            href={`https://tarot.saberastro.com`}
            rel="noopener noreferrer"
            target="_blank"
          >
            HERE
          </a>{' '}
          to get started via your web browser.
        </Base.Paragraph>
        <Base.Paragraph>
          Subscribed? Head on over to the{' '}
          <a href={'https://saberastro.com/downloads'}>Downloads</a> page to get
          the latest version of PIGI.
        </Base.Paragraph>
      </div>
    );
  } else if (success === false) {
    displayElement = (
      <div>
        <Base.Heading>Registration Failed.</Base.Heading>
        <Base.Paragraph>{message}</Base.Paragraph>
        <Base.Paragraph>
          To reattempt registration, please try again on the signup page{' '}
          <a href={`/register`} rel="noopener noreferrer" target="_blank">
            HERE
          </a>
          .
        </Base.Paragraph>
      </div>
    );
  }

  return <Hero.Text style={{ margin: 0 }}>{displayElement}</Hero.Text>;
}

export default ConfirmAccount;
