import { iamClient } from 'src/clients';

export default async function (email: string): Promise<void> {
  await iamClient
    .post(`/password/reset`, {
      email: email,
    })
    .catch((err) =>
      Promise.reject(
        new Error(
          err?.response?.data?.msg ??
            'Unexpected error. Please try again or contact us.',
        ),
      ),
    );
}
