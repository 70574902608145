import React from 'react';
import styled from 'styled-components';

import { FiCheck } from 'react-icons/fi';
import { Button, Typography } from 'amphitheatre';

import { SuccessText } from '../App/App.styled';

import { WEBSITE_BASE_URL } from 'src/Components/App/AppGlobals';

const Downloads = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1rem;

  @media (min-width: 1100px) {
    flex-flow: row;
  }

  div {
    flex-basis: 50%;
  }
`;

const { Header, Paragraph } = Typography;

interface Props {
  reset: () => void;
}

function PaymentSuccessful({ reset }: Props): React.ReactElement {
  return (
    <>
      <div className="uk-text-center uk-padding">
        <SuccessText>
          <FiCheck />
        </SuccessText>
        <div>
          <Header level={3}>Payment Successful!</Header>
        </div>
        <Paragraph>Download P.I.G.I to get started!</Paragraph>
      </div>
      <Downloads>
        <div>
          <a href={`${WEBSITE_BASE_URL}/downloads`}>
            <Button fill>Download P.I.G.I</Button>
          </a>
        </div>
        <div>
          <Button fill ghost onClick={reset}>
            Make Another Purchase
          </Button>
        </div>
      </Downloads>
    </>
  );
}

export default PaymentSuccessful;
