import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Loader } from 'amphitheatre';
import { FiChevronsRight } from 'react-icons/fi';

import { ButtonText } from '../PurchaseForm/PurchaseForm.styled';
import { RootState } from '../../../Redux/Reducers';
import BuyCard from './BuyCard';

function mapStateToProps(state: RootState) {
  const { plans } = state.license;
  return { plans };
}

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector> & {
  /* The id of the plan the user has selected (Refer to licenses below) */
  selectedPlan?: string;

  /* Informs the parent to move to the next page */
  proceed: () => void;

  /* Informs the plan the user has selected */
  select: (id: string, price: string) => void;
};

function SelectPlan(props: Props) {
  const { plans, proceed, select, selectedPlan } = props;

  const onSelect = (id: string, price: string) => {
    select(id, price);
  };

  return (
    <>
      <div className="uk-margin uk-text-center">
        {plans ? (
          <div className="uk-margin">
            <BuyCard
              data={plans}
              onSelect={onSelect}
              selectedPlan={selectedPlan}
            />
          </div>
        ) : (
          <Loader message="Loading Licenses ..." />
        )}
      </div>
      <div className="uk-text-right">
        <Button disabled={!selectedPlan} onClick={proceed}>
          <ButtonText>
            Select Plan
            <FiChevronsRight />
          </ButtonText>
        </Button>
      </div>
    </>
  );
}

export default connector(SelectPlan);
