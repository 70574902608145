import { iamClient } from 'src/clients';

export default function (data: Record<string, unknown>): Promise<void> {
  const { username, password, email } = data as {
    username: string;
    password: string;
    email: string;
  };

  return new Promise((resolve, reject) =>
    iamClient
      .post('/users', { username, password, email })
      .then(() => resolve())
      .catch((error) => {
        if (error.response.data.msg) {
          reject(new Error(error.response.data.msg));
        }
        reject(new Error('Unexpected error. Please try again or contact us.'));
      }),
  );
}
