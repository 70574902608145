import styled, { createGlobalStyle } from 'styled-components';

export const SuccessText = styled.div`
  color: ${({ theme }) => theme.success500};
  font-size: 5em;
`;

export const BodyStyle = createGlobalStyle`
  html {
    background-color: ${({ theme }) => theme.bg};
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
  }

  body { margin: 0; }

  .uk-margin { margin-bottom: 20px; }
  * + .uk-margin {
    margin-top: 20px;
  }
  .uk-margin-top { margin-top: 20px; }
  .uk-margin-small-right { margin-right: 10px; }
  .uk-text-right { text-align: right; }
  .uk-text-center { text-align: center; }
  .uk-text-success { color: ${({ theme }) => theme.success500}; }
  .uk-text-bolder { font-weight: bolder; }

  .uk-padding {
    padding: 30px;
  }

  /* Desktop and bigger */
  @media (min-width: 1200px) {
    .uk-padding {
      padding: 40px;
    }
  }
`;

export const HeaderSpacer = styled.div`
  padding: 1em;
`;
