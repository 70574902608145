/* eslint-disable camelcase */
// Disable for this file as data is retrieved in camel case
import { AxiosError } from 'axios';
import store from '../Redux/store';

import {
  setPlans,
  setSubscriptions,
  setExpiredSubscriptions,
} from '../Redux/Reducers/license';
import {
  setUserId,
  setName,
  setEmail,
  setIsEnterpriseMember,
  setHasInvites,
} from '../Redux/Reducers/user';
import { setTokens } from '../Redux/Reducers/tokenHistory';
import { toastError } from './toast';
import { iamClient } from 'src/clients';
import { Memberships, Invites } from './types';

export function getIsEnterpriseMember(): void {
  iamClient
    .get<Memberships>('/user/memberships')
    .then((res) => {
      const filteredMemberships = res.data.memberships.filter(
        (x) =>
          x.account_type === 'enterprise' ||
          x.account_type === 'casualgroup' ||
          x.account_type === 'mission_1',
      );
      store.dispatch(setIsEnterpriseMember(filteredMemberships.length > 0));
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 401) {
        store.dispatch(setIsEnterpriseMember(false));
      } else {
        toastError('Call to api failed for some reason!');
      }
    });
}

export function getHasInvites(): void {
  iamClient
    .get<Invites>('/user/invites')
    .then((res) => {
      store.dispatch(setHasInvites(res.data.invites.length > 0));
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 401) {
        store.dispatch(setHasInvites(false));
      } else {
        toastError('Call to api failed for some reason!');
      }
    });
}

export function getUserDetail(): void {
  iamClient
    .get('/user')
    .then((res) => {
      const { id, email, username } = res.data;
      store.dispatch(setUserId(id));
      store.dispatch(setEmail(email));
      store.dispatch(setName(username));
    })
    .catch((err) => {
      if (err.response) {
        toastError(err.response.data.msg);
      } else {
        toastError('Call to api failed for some reason!');
      }
    });
}

export function getPlans(): void {
  iamClient
    .get('/plans')
    .then((res) => {
      const { pigi_plans } = res.data;
      store.dispatch(setPlans(pigi_plans));
    })
    .catch((err) => {
      if (err.response) {
        toastError(err.response.data.msg);
      } else {
        toastError('Call to api failed for some reason!');
      }
    });
}

export function getMyPurchases(): void {
  clearMyPurchases();
  iamClient
    .get('/subscriptions')
    .then((res) => {
      const { active_products, inactive_products } = res.data;
      if (active_products) {
        store.dispatch(setSubscriptions(active_products));
      } else {
        store.dispatch(setSubscriptions([]));
      }

      if (inactive_products) {
        store.dispatch(setExpiredSubscriptions(inactive_products));
      } else {
        store.dispatch(setExpiredSubscriptions([]));
      }
    })
    .catch((err) => {
      if (err.response) {
        toastError(err.response.data.msg);
      } else {
        toastError('Call to api failed for some reason!');
      }
    });
}

export function getTokenHistory(): void {
  // TODO: Add call to api to retrieve token history
  store.dispatch(
    setTokens([]),
    // setTokens([
    //   {
    //     device:
    //       'Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:84.0) Gecko/20100101 Firefox/84.0',
    //     expires: '0',
    //     ips: '127.0.0.1',
    //     jti: 'id',
    //     revoked: 'false',
    //     type: 'Access',
    //   },
    //   {
    //     device:
    //       'Mozilla/5.0 (iPhone; CPU iPhone OS 13_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 [FBAN/FBIOS;FBDV/iPhone11,8;FBMD/iPhone;FBSN/iOS;FBSV/13.3.1;FBSS/2;FBID/phone;FBLC/en_US;FBOP/5;FBCR/]',
    //     expires: '1708617751000',
    //     ips: '127.0.0.1',
    //     jti: 'id',
    //     revoked: 'false',
    //     type: 'Access',
    //   },
    //   {
    //     device:
    //       'Mozilla/5.0 (Linux; Android 10; moto g(7) power Build/QCOS30.85-18-6; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/86.0.4240.185 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/294.0.0.39.118;]',
    //     expires: '1708617751000',
    //     ips: '127.0.0.1',
    //     jti: 'id',
    //     revoked: 'false',
    //     type: 'Access',
    //   },
    //   {
    //     device:
    //       'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36',
    //     expires: '0',
    //     ips: '127.0.0.1',
    //     jti: 'id',
    //     revoked: 'false',
    //     type: 'Access',
    //   },
    //   {
    //     device:
    //       'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/44.0.2403.157 Safari/537.36',
    //     expires: '0',
    //     ips: '127.0.0.1',
    //     jti: 'id',
    //     revoked: 'false',
    //     type: 'Refresh',
    //   },
    // ]),
  );
}

// ============================================================================
// Clear data functions
// ============================================================================

export function clearMyPurchases(): void {
  store.dispatch(setSubscriptions(null));
  store.dispatch(setExpiredSubscriptions(null));
}

export function clearData(): void {
  store.dispatch(setSubscriptions(null));
  store.dispatch(setExpiredSubscriptions(null));
  store.dispatch(setIsEnterpriseMember(null));
  store.dispatch(setEmail(''));
  store.dispatch(setName(''));
  store.dispatch(setTokens(null));
}
