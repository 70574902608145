import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Form, Typography } from 'amphitheatre';

import { toastError, toastSuccess, validateEmail } from 'src/Utils';

import callResendConfirmation from './ResendConfirmation.action';

const { Header, Paragraph } = Typography;

export function ResendConfirmation(): React.ReactElement {
  const [loading, setLoading] = useState(false);
  const [reconfirmSent, setReconfirmSent] = useState(false);

  const onReconfirm = async (email: Record<string, unknown>) => {
    setLoading(true);
    await callResendConfirmation(email)
      .then(() => {
        setLoading(false);
        toastSuccess('User confirmation resent - please check your email.');
        setReconfirmSent(true);
      })
      .catch((err) => {
        setLoading(false);
        toastError(err.response.data.msg);
      });
  };

  if (reconfirmSent) {
    return <Redirect to="/login" />;
  } else {
    return (
      <div className="uk-margin-top">
        <Form onSubmit={onReconfirm} id={'Resend Form'}>
          <span className="uk-text-center">
            <Header level={1}>Your Account is unconfirmed</Header>
            <Paragraph>
              Please check your email or request a new confirmation email using
              the form below.
            </Paragraph>
          </span>
          <Form.Validator
            name="email"
            validators={[
              {
                validator: (value) => validateEmail(value),
                errorMessage: 'Email is not a valid format.',
              },
            ]}
            required
          >
            <Form.Input label="Email" aria-label="Email" data-testid="email" />
          </Form.Validator>
          <Button
            type="submit"
            fill
            loading={loading}
            data-testid="submit-button"
          >
            Resend Confirmation Email
          </Button>
        </Form>
      </div>
    );
  }
}

export default ResendConfirmation;
