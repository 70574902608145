import { iamClient } from 'src/clients';

export default async function (
  email: string,
  username: string,
  password: string,
  token: string | null,
): Promise<void> {
  await iamClient
    .post('/invites/accept', {
      email: email,
      username: username,
      password: password,
      token: token,
    })
    .catch((err) =>
      Promise.reject(
        new Error(
          err?.response?.data?.msg ??
            'Unexpected error. Please try again or contact us.',
        ),
      ),
    );
}
