import styled from 'styled-components';

export default styled.div`
  background-color: ${({ theme }) => theme.licenseBg};
  border: 1px solid grey;
  border-radius: 3px;
  box-sizing: border-box;
  align-self: flex-start;
  flex-basis: 33%;

  margin-bottom: 1rem;
  padding: 1rem;
  width: 100%;

  margin-left: 1rem;
`;

export const CartLine = styled.div`
  color: ${({ theme }) => theme.fontColour};
  font-family: ${({ theme }) => theme.fontBody};
  display: flex;
  justify-content: space-between;
`;
