export const ACCESS_TOKEN = 'accessTokenAccount';
export const REFRESH_TOKEN = 'refreshTokenAccount';

export let WEBSITE_BASE_URL = 'http://localhost:3000';
export let API_BASE_URL = 'http://localhost:8000';

switch (process.env.REACT_APP_ENV) {
  case 'development':
    WEBSITE_BASE_URL = 'https://saberdev.xyz';
    API_BASE_URL = 'https://account.saberdev.xyz/api';
    break;

  case 'staging':
    WEBSITE_BASE_URL = 'https://saberstaging.xyz';
    API_BASE_URL = 'https://account.saberstaging.xyz/api';
    break;

  case 'production':
    WEBSITE_BASE_URL = 'https://saberastro.com';
    API_BASE_URL = 'https://account.saberastro.com/api';
    break;

  default:
    break;
}
