import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from 'amphitheatre';
import { withTheme, DefaultTheme } from 'styled-components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import StyledRefreshModal from './RefreshModal.styled';
import { RootState } from 'src/Redux/Reducers';
import { deleteAllTokens } from 'src/Redux/Reducers/tokens';
import { getAccessExpiry, refreshAccessToken } from 'src/Utils';

const COUNTDOWN = 120; // In seconds

function mapStateToProps(state: RootState) {
  const { accessToken } = state.tokens;
  return { accessToken };
}

const connector = connect(mapStateToProps, {
  _deleteAllTokens: deleteAllTokens,
});

type Props = ConnectedProps<typeof connector> & {
  theme: DefaultTheme;
};
interface State {
  loading: boolean;
  remainingTime: number;
  visible: boolean;
}

export class RefreshModal extends React.Component<Props, State> {
  state = { loading: false, remainingTime: COUNTDOWN, visible: false };

  refreshTimer: NodeJS.Timeout | undefined = undefined;
  countdown: NodeJS.Timeout | undefined = undefined;

  startCountDown = (): void => {
    this.countdown = global.setInterval(() => {
      const { remainingTime } = this.state;
      this.setState({ remainingTime: remainingTime - 1 });
    }, 1000);
    this.setState({ visible: true });
  };

  refresh = (): void => {
    refreshAccessToken();
    this.setState({ loading: true });
  };

  reset = (): void => {
    clearTimeout(this.refreshTimer as NodeJS.Timeout);
    clearInterval(this.countdown as NodeJS.Timeout);

    this.refreshTimer = undefined;
    this.countdown = undefined;

    this.setState({ loading: false, remainingTime: COUNTDOWN, visible: false });
  };

  componentDidUpdate = (prevProps: Props, prevState: State): void => {
    const { remainingTime } = this.state;
    const { _deleteAllTokens, accessToken } = this.props;

    if (prevState.remainingTime !== remainingTime) {
      if (remainingTime <= 0) {
        clearInterval(this.countdown as NodeJS.Timeout);
        this.countdown = undefined;

        _deleteAllTokens();
      }
    }

    if (prevProps.accessToken !== accessToken) {
      if (accessToken && !this.refreshTimer) {
        const accessExpiry = getAccessExpiry();

        if (accessExpiry) {
          this.refreshTimer = global.setTimeout(
            () => this.startCountDown(),
            accessExpiry,
          );
        }
      } else {
        this.reset();
      }
    }
  };

  render(): JSX.Element {
    const { loading, remainingTime, visible } = this.state;
    const { theme } = this.props;

    const progressValue = (remainingTime / COUNTDOWN) * 100;

    return (
      <StyledRefreshModal
        theme={theme}
        data-visible={visible}
        data-testid="refresh-modal"
      >
        <div className="progress">
          <CircularProgressbar
            value={progressValue}
            strokeWidth={50}
            styles={buildStyles({
              strokeLinecap: 'butt',
              pathColor: theme.primary500,
              trailColor: 'transparent',
            })}
          />
        </div>
        <div>
          <p>
            Your session has expired. Please refresh your session before you are
            automatically logged out!
          </p>
          <Button
            onClick={this.refresh}
            loading={loading}
            disabled={loading}
            type="button"
            fill
          >
            Refresh Session
          </Button>
        </div>
      </StyledRefreshModal>
    );
  }
}

export default connector(withTheme(RefreshModal));
