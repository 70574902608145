import React from 'react';
import { Link } from 'react-router-dom';

import { FiCheck } from 'react-icons/fi';
import { Button, Typography } from 'amphitheatre';

import { SuccessText } from '../App/App.styled';

const { Paragraph } = Typography;

function SuccessDisplay(): React.ReactElement {
  return (
    <div className="uk-container uk-text-center uk-padding">
      <SuccessText>
        <FiCheck />
      </SuccessText>
      <Paragraph>Your password has been successfully reset</Paragraph>
      <Paragraph>Please sign in again.</Paragraph>
      <div className="uk-margin">
        <Link to="/login">
          <Button fill>Return to Sign in</Button>
        </Link>
      </div>
    </div>
  );
}

export default SuccessDisplay;
