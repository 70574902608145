import React, { useRef } from 'react';
import IdleTimer from 'react-idle-timer';

import { checkAccessToken } from '../../Utils';

export default function AppIdle(): React.ReactElement {
  const idleTimer = useRef(null);

  function handleOnAction() {
    checkAccessToken();
  }

  return (
    <>
      <IdleTimer
        ref={idleTimer as never}
        timeout={1000 * 60 * 15}
        onAction={handleOnAction}
        eventsThrottle={1000}
      />
    </>
  );
}
