import { iamClient } from 'src/clients';

export default function (name: string): Promise<boolean> {
  return new Promise((resolve, reject) =>
    iamClient
      .get(`users/availability/${name}`)
      .then((res) => {
        resolve(res.data?.available ?? false);
      })
      .catch((error) => {
        if (error.response.data.msg) {
          reject(new Error(error.response.data.msg));
        }
        reject(new Error('Unexpected error. Please try again or contact us.'));
      }),
  );
}
