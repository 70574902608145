import { Themes } from 'amphitheatre';

import SaberTheme from './theme.type';

const CustomLight: SaberTheme = {
  ...Themes.saberLight,

  bg: '#fffcf7',
  editorBg: '#ffffff',
  editText: '#57534e',
  licenseBg: '#ffffff',
  licenseBgDisabled: Themes.saberLight.grey700,
  licenseBgDisabledBorder: Themes.saberLight.grey500,
  licenseShadow:
    '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  tableHeaderText: '#e6eff6',

  stepsDisabledColour: '#6c757d',
};

export default CustomLight;
