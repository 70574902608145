import styled from 'styled-components';

export const StyledBuyCard = styled.div`
  box-shadow: ${({ theme }) => theme.licenseShadow};
  background-color: ${({ theme }) => theme.licenseBg};

  display: flex;
  flex-flow: row;

  button {
    padding: 0.5em 1em;
  }

  text-align: left;

  padding: 1.5em 2em;
`;

export const Logo = styled.img`
  width: 196px;
  margin: auto;
`;

export const Content = styled.div`
  flex-grow: 1;

  padding: 0 2em;
`;

export const PriceBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${({ theme }) => theme.fontHeader};
  flex-basis: 25%;

  position: relative;
`;

export const DollarSign = styled.span`
  font-size: 18px;
`;

export const Price = styled.div`
  color: ${({ theme }) => theme.primary400};

  font-size: 48px;

  margin-bottom: 0.5em;
`;

export const OldPrice = styled.div`
  color: ${({ theme }) => theme.grey600};

  font-size: 24px;

  position: absolute;
  top: calc(50% + 12px);

  text-decoration: line-through;
`;
