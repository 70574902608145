import { Invoice } from '../License/License.types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Image } from 'src/page-styles/base.styled';
import { toastError, assertIsError } from 'src/Utils';
import horizontal_blue_transparent from './horizontal_blue_transparent.png';
import darkLogo from 'src/Components/App/logo.png';
import getInvoice from './InvoicePage.action';
import { Typography, Loader, Button } from 'amphitheatre';
import { InvoiceStyle } from './invoicePage.styled';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Redux/Reducers';
import { setDarkMode } from 'src/Redux/Reducers/darkMode';
import store from 'src/Redux/store';

const { Header } = Typography;

export default function (): JSX.Element {
  const { invoice_id } = useParams<{ invoice_id: string }>();
  const [invoice, setInvoice] = useState<Invoice>();
  const [loading, setLoading] = useState<boolean>(false);
  const darkMode = useSelector((state: RootState) => state.darkMode);

  const printScreen = async () => {
    if (darkMode) {
      await store.dispatch(setDarkMode(false));
      window.print();
      store.dispatch(setDarkMode(true));
    } else {
      window.print();
    }
  };

  const loadInvoice = async () => {
    setLoading(true);
    try {
      setInvoice(await getInvoice(invoice_id));
      setLoading(false);
    } catch (err) {
      assertIsError(err);
      toastError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadInvoice();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let dispEl = <div></div>;
  if (loading) {
    dispEl = (
      <div className="uk-text-center">
        <Loader message="Loading Invoice Details..." />
      </div>
    );
  } else if (invoice) {
    const add_ons: Array<JSX.Element> = [];
    for (let i = 0; i < invoice.add_ons.length; i++) {
      const add_on = invoice.add_ons[i];
      add_ons.push(
        <tr className="item">
          <td>Add on: {add_on.name}</td>
          <td>${add_on.amount}</td>
        </tr>,
      );
    }

    const discounts: Array<JSX.Element> = [];

    for (let i = 0; i < invoice.discounts.length; i++) {
      const discount = invoice.discounts[i];
      discounts.push(
        <tr className="item">
          <td>Discount: {discount.name}</td>
          <td>-${discount.amount}</td>
        </tr>,
      );
    }

    dispEl = (
      <html>
        <div>
          <body>
            <div className="invoice-box">
              <table cellPadding="0" cellSpacing="0">
                <tr className="top">
                  <td colSpan={2}>
                    <table>
                      <tr>
                        <td className="title">
                          <Image
                            src={
                              darkMode ? darkLogo : horizontal_blue_transparent
                            }
                            style={{ width: '100%', maxWidth: '300px' }}
                            alt="horizontal blue square"
                          />
                        </td>
                        <td>
                          Receipt ID# {invoice.id}
                          <br />
                          Payment Date: {invoice.period_start}
                          <br />
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr className="information">
                  <td colSpan={2}>
                    <table>
                      <tr>
                        <td>
                          <h1>Payment Receipt</h1>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Saber Astronautics Australia Pty. Ltd.
                          <br />
                          Suite 2, 63-71 Balfour St
                          <br />
                          Chippendale, NSW, 2008
                          <br />
                          <a href="https://www.saberastro.com">
                            www.saberastro.com
                          </a>
                        </td>
                        <td>
                          {invoice.first_name} {invoice.last_name}
                          <br />
                          {invoice.email}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr className="heading">
                  <td>Payment Method</td>
                  <td>
                    {invoice.payment_method === 'credit_card' && (
                      <> Card # (Last Four Digits) </>
                    )}
                    {invoice.payment_method !== 'credit_card' && (
                      <> Email Address </>
                    )}
                  </td>
                </tr>
                <tr className="details">
                  <td>
                    {invoice.payment_method === 'credit_card' &&
                      invoice.card_type}
                    {invoice.payment_method === 'paypal_account' && (
                      <> PayPal </>
                    )}
                  </td>
                  <td>
                    {invoice.payment_method === 'credit_card' &&
                      invoice.card_last_four}
                    {invoice.payment_method === 'paypal_account' &&
                      invoice.email}
                  </td>
                </tr>
                <tr className="heading">
                  <td>Item</td>

                  <td>Price</td>
                </tr>

                <tr className="item">
                  <td>
                    {invoice.plan.name} ({invoice.period_start} to{' '}
                    {invoice.period_end})
                  </td>
                  <td>${invoice.plan.amount}</td>
                </tr>
                {add_ons}
                {discounts}
                <tr className="item">
                  <td>GST</td>
                  <td>${invoice.gst}</td>
                </tr>
                <tr className="total">
                  <td></td>
                  <td>Total: ${invoice.amount}</td>
                </tr>
              </table>
              <p className="text-center">
                Saber Astronautics &copy; {invoice.period_start}
                <br />
                ABN: 80 133 482 881
                <br />
              </p>
            </div>
            <br />
            <Button
              onClick={() => {
                printScreen();
              }}
            >
              Print
            </Button>
          </body>
        </div>
      </html>
    );
  } else {
    dispEl = <Header>Invoice Not Found.</Header>;
  }
  const retEl = (
    <div>
      {' '}
      <InvoiceStyle>{dispEl}</InvoiceStyle>
    </div>
  );
  return retEl;
}
