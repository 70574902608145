/* eslint-disable react/jsx-one-expression-per-line */
import { iamClient } from 'src/clients';
import { Invoice, Transaction } from './License.types';

export default async function (id: string): Promise<Invoice[]> {
  return new Promise<Invoice[]>((resolve, reject) =>
    iamClient
      .get(`/subscriptions/${id}/transactions`)
      .then((res) => {
        const { transactions } = res.data;

        const details: Array<Record<string, unknown>> = transactions.map(
          (trans: Transaction) => iamClient.get(`/transactions/${trans.id}`),
        );

        Promise.all(details).then((results) => {
          resolve(results.map((result) => result.data as Invoice));
        });
      })
      .catch((err) => {
        reject(
          new Error(
            err?.response?.data?.msg ??
              'Unexpected error. Please try again or contact us.',
          ),
        );
      }),
  );
}
