import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import parser from 'ua-parser-js';
import { CSSTransition } from 'react-transition-group';

import { BsThreeDotsVertical } from 'react-icons/bs';
import {
  FaApple,
  FaMicrosoft,
  FaQuestionCircle,
  FaLinux,
  FaUbuntu,
} from 'react-icons/fa';
import { MdAndroid } from 'react-icons/md';

import {
  Button,
  Content,
  Expired,
  ExpiresIn,
  Expiry,
  ExpiryTime,
  Location,
  Icon,
  Name,
  Token,
  Dropdown,
} from './Token.styled';

interface Props {
  device: string;
  expires: string;
  ips: string;
  jti: string;
  revoked: string;
  type?: string;
}

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export default function ({
  device,
  expires,
  ips,
  jti,
  revoked,
  type,
}: Props): JSX.Element {
  const [clicked, setClicked] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const ua = new parser(device);
  const browser = ua.getBrowser().name;
  const os = ua.getOS().name?.toLowerCase();
  const osName = ua.getOS().name || 'Unknown device';

  const now = moment();
  const expire = moment(parseInt(expires));
  const expired = now.isAfter(expire) || revoked.toLowerCase() === 'true';
  const expiresIn = expire.diff(now) / 1000;

  let expiresInStr = 'Last than a minute';

  if (expiresIn > DAY) {
    const left = Math.trunc(expiresIn / DAY);
    expiresInStr = `${left} day${left - 1 ? 's' : ''}`;
  } else if (expiresIn > HOUR) {
    const left = Math.trunc(expiresIn / HOUR);
    expiresInStr = `${left} hour${left - 1 ? 's' : ''}`;
  } else if (expiresIn > MINUTE) {
    const left = Math.trunc(expiresIn / MINUTE);
    expiresInStr = `${left} minute${left - 1 ? 's' : ''}`;
  }

  let icon = <FaQuestionCircle />;

  if (os === 'ubuntu') {
    icon = <FaUbuntu data-testid="ubuntu" />;
  } else if (os === 'linux') {
    icon = <FaLinux data-testid="linux" />;
  } else if (os === 'ios') {
    icon = <FaApple data-testid="ios" />;
  } else if (os === 'android') {
    icon = <MdAndroid data-testid="android" />;
  } else if (os === 'windows') {
    icon = <FaMicrosoft data-testid="windows" />;
  }

  function toggleMenu() {
    setClicked(!clicked);
  }

  useEffect(() => {
    const listener: EventListenerOrEventListenerObject = (event) => {
      const target = event.target as Node;
      if (buttonRef.current?.contains(target)) {
        return;
      }
      if (dropdownRef.current?.contains(target)) {
        return;
      }
      setClicked(false);
    };
    document.addEventListener('mousedown', listener);
    return () => document.removeEventListener('mousedown', listener);
  }, []);

  return (
    <Token>
      <Icon>{icon}</Icon>
      <Content>
        <Name>
          {osName} {browser ? ` - ${browser}` : null}
        </Name>
        <Location>
          Last logged in from <b>{ips}</b>
        </Location>
      </Content>
      {expired ? (
        <Expired>Revoked!</Expired>
      ) : (
        <ExpiresIn>
          <Expiry>Expires in</Expiry>
          <ExpiryTime>{expiresInStr}</ExpiryTime>
        </ExpiresIn>
      )}
      {expired ? null : (
        <Button onClick={toggleMenu} ref={buttonRef} data-testid="btn-dropdown">
          <BsThreeDotsVertical />
        </Button>
      )}
      <CSSTransition
        in={clicked && !expired}
        timeout={250}
        classNames="dropdown"
        nodeRef={dropdownRef}
      >
        <Dropdown ref={dropdownRef} data-testid="dropdown">
          <ul>
            <li>
              <button>Sign out</button>
            </li>
          </ul>
        </Dropdown>
      </CSSTransition>
    </Token>
  );
}
