import React from 'react';
import styled from 'styled-components';
import { connect, ConnectedProps } from 'react-redux';
import { Loader, Typography } from 'amphitheatre';

import { RootState } from 'src/Redux/Reducers';

import Username from './Username';
import Email from './Email';
import Password from './Password';

const { Header } = Typography;

const CustomContainer = styled.div`
  align-self: flex-start;
  margin: 0;
  padding: 1em 2em;

  @media (min-width: 1100px) {
    border: 1px solid grey;
    border-radius: 10px;
  }
`;

function mapStateToProps(state: RootState) {
  const { email, name } = state.user;
  return { email, name };
}

const connector = connect(mapStateToProps, {});

type UserProp = ConnectedProps<typeof connector>;

export function User({ email, name }: UserProp): JSX.Element {
  const display =
    email && name ? (
      <>
        <div className="uk-margin">
          <Username />
        </div>
        <div className="uk-margin">
          <Email />
        </div>
        <div className="uk-margin">
          <Password />
        </div>
      </>
    ) : (
      <div className="uk-text-center">
        <Loader message="Loading User Details..." />
      </div>
    );

  return (
    <CustomContainer>
      <Header level={1}>My Details</Header>
      {display}
    </CustomContainer>
  );
}

export default connector(User);
