import styled from 'styled-components';

export default styled.div`
  flex-basis: 66%;

  [data-braintree-id='upper-container'] {
    &::before {
      background-color: ${({ theme }) => theme.bg};
    }
  }

  [data-braintree-id='paypal'],
  [data-braintree-id='card'] {
    background-color: ${({ theme }) => theme.bg};
    border: 1px solid ${({ theme }) => theme.grey500};
  }

  [data-braintree-id='card-sheet-header'] {
    border-bottom: 1px solid ${({ theme }) => theme.grey500};
  }

  [data-braintree-id='sheet-error'],
  [data-braintree-id='number-field-error'],
  [data-braintree-id='expiration-date-field-error'],
  [data-braintree-id='cvv-field-error'],
  [data-braintree-id='sheet-error-text'] {
    color: red !important;
  }

  [data-braintree-id='payment-options-container'] .braintree-option {
    background-color: ${({ theme }) => theme.bg};

    .braintree-option__label {
      color: ${({ theme }) => theme.fontColour};
    }
  }

  [data-braintree-id='toggle'] {
    background-color: ${({ theme }) => theme.bg};

    span {
      color: ${({ theme }) => theme.fontColour};
    }
  }

  [data-braintree-id='choose-a-way-to-pay'],
  [data-braintree-id='paypal-sheet-header'] {
    color: ${({ theme }) => theme.fontColour};
  }

  [data-braintree-id='methods-label'] {
    color: ${({ theme }) => theme.fontColour};
  }

  [data-braintree-id='methods-container'] .braintree-method {
    background-color: transparent;

    & .braintree-method__label {
      color: ${({ theme }) => theme.fontColour};
    }
  }

  [data-braintree-id='other-ways-to-pay'] {
    color: ${({ theme }) => theme.fontColour};
  }

  .braintree-sheet__content--form
    .braintree-form__field-group
    .braintree-form__label {
    color: ${({ theme }) => theme.fontColour};
  }

  .braintree-sheet__text {
    color: ${({ theme }) => theme.fontColour};
  }

  [data-braintree-id='methods-container'] svg {
    height: 100% !important;
  }
`;

export const AnotherWrapper = styled.div`
  display: flex;
  flex-flow: row-reverse;

  #form {
    flex-grow: 1;
  }
`;
