import styled from 'styled-components';

export default styled.div`
  background-color: ${({ theme }) => theme.modalBg};
  color: ${({ theme }) => theme.fontColour};
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
  border-left: 5px solid ${({ theme }) => theme.primary400};
  position: fixed;

  display: flex;

  right: 0;
  bottom: 0;
  z-index: 10000;

  max-width: 312px;

  margin: 16px;
  padding: 1em;

  transition: all 0.25s ease-out;

  .progress {
    display: flex;
    align-items: center;
    padding-right: 1em;
    width: 96px;
    min-width: 96px;
  }

  &[data-visible='false'] {
    opacity: 0;
    right: -400px;
  }
`;
