import axios from 'axios';

import { API_BASE_URL } from './Components/App/AppGlobals';

export const iamClient = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    post: {
      'Content-Type': 'application/json',
    },
  },
});
