import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  filter: string | null;
  sorter: string | null;
}

export const initialState: State = {
  filter: null,
  sorter: 'SORT_BILLING_ASC',
};

const filterSlice = createSlice({
  name: 'licenseFilter',
  initialState,
  reducers: {
    setLicenseFilter: (state, action: PayloadAction<string | null>) => {
      const { payload } = action;
      state.filter = payload;
    },
    setLicenseSorter: (state, action: PayloadAction<string | null>) => {
      const { payload } = action;
      state.sorter = payload;
    },
  },
});

export const { setLicenseFilter, setLicenseSorter } = filterSlice.actions;

export default filterSlice.reducer;
