import React from 'react';
import { Link } from 'react-router-dom';

import { FiCheck } from 'react-icons/fi';
import { Button, Typography } from 'amphitheatre';

import { SuccessText } from '../App/App.styled';

const { Paragraph } = Typography;

function SuccessDisplay(): React.ReactElement {
  return (
    <div className="uk-container uk-text-center uk-padding">
      <SuccessText>
        <FiCheck />
      </SuccessText>
      <div>
        <Paragraph>
          You have successfully registered and joined the team. You can now log
          in with your new credentials.
        </Paragraph>
      </div>
      <Link to="/login">
        <Button fill>Return to login</Button>
      </Link>
    </div>
  );
}

export default SuccessDisplay;
