/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { FaUserCircle } from 'react-icons/fa';
import { Button, Form, Loader, Modal, Table, Typography } from 'amphitheatre';

import {
  toastError,
  validateEmail,
  Account,
  getIsEnterpriseMember,
} from '../../Utils';
import ButtonStyle from './MyTeams.styled';
import { iamClient } from 'src/clients';

const { Header } = Typography;

export type ManageAccountProps = {
  accountId: string;
  userId: string;
  onDone: () => void;
};

const ManageAccount = ({
  accountId,
  userId,
  onDone,
}: ManageAccountProps): JSX.Element => {
  const [account, setAccount] = useState<Account>();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const fetchAccount = async (accountId: string) => {
    const { data } = await iamClient.get<Account>(`/accounts/${accountId}`);
    setAccount(data);
  };

  useEffect(() => {
    (() => fetchAccount(accountId))();
  }, [accountId]);

  async function removeMembership(accountId: string, membershipId: string) {
    setLoading(true);
    try {
      await iamClient.delete(`/memberships/${membershipId}`);
      if (account) {
        const membership = account.memberships.find(
          (m) => m.id === membershipId,
        );
        if (membership?.user_id === userId) {
          getIsEnterpriseMember();
          return onDone();
        }
        setAccount({
          ...account,
          memberships: account.memberships.filter((m) => m.id !== membershipId),
        });
        await fetchAccount(accountId);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data.msg) {
        toastError(error.response.data.msg);
      } else {
        toastError('Call to api failed for some reason!');
      }
    }
    setLoading(false);
  }

  async function removeInvite(accountId: string, inviteId: string) {
    setLoading(true);
    try {
      await iamClient.patch(`/invites/${inviteId}`, {
        status: 'revoked',
      });
      if (account) {
        // const invite = account.invites.find((i) => i.id === inviteId);
        setAccount({
          ...account,
          invites: account.invites.filter((i) => i.id !== inviteId),
        });
        await fetchAccount(accountId);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data.msg) {
        toastError(error.response.data.msg);
      } else {
        toastError('Call to api failed for some reason!');
      }
    }
    setLoading(false);
  }

  async function inviteMember(
    accountId: string,
    data: Record<string, unknown>,
  ) {
    setLoading(true);
    setModalVisible(false);

    const { email } = data as { email: string };

    try {
      await iamClient.post(`/accounts/${accountId}/invites`, {
        email,
      });
      await fetchAccount(accountId);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data.msg) {
        toastError(error.response.data.msg);
      } else {
        toastError('Call to api failed for some reason!');
      }
    }

    setLoading(false);
  }

  function toggleModal() {
    setModalVisible(!modalVisible);
  }

  if (!account)
    return (
      <div className="uk-text-center">
        <Loader message="Loading Account Details..." />
      </div>
    );

  const hasOtherAdmin =
    account.memberships.reduce(
      (acc, m) =>
        m.role === 'administrator' && m.user_id !== userId ? ++acc : acc,
      0,
    ) > 0;

  const membership_columns = [
    {
      id: 'username',
      text: 'Username',
      width: '15%',
      sorted: true,
      sorter: (a: string, b: string) => {
        return a.localeCompare(b);
      },
    },
    {
      id: 'email',
      text: 'Email',
      width: '25%',
      sorted: true,
    },
    {
      id: 'role',
      text: 'Role',
      width: '25%',
      render: (a: unknown) =>
        (a as string).replace(/./, (x) => x.toUpperCase()),
    },
    {
      id: 'date',
      text: 'Date Added',
      width: '30%',
      sorted: true,
      sorter: (a: string, b: string) => {
        if (moment(a).isBefore(moment(b))) return -1;
        else if (moment(a).isAfter(moment(b))) return 1;
        return 0;
      },
      render: (a: unknown) =>
        moment(a as string).format('dddd, MMMM Do YYYY, h:mm:ss a'),
    },
    {
      id: 'manage',
      text: 'Manage',
      width: '15%',
    },
  ];

  const membership_data = account.memberships.map((m, index) => ({
    id: index,
    username: m.user_username,
    email: m.user_email,
    role: m.role,
    date: m.created_at,
    manage: (
      <>
        <Button
          loading={loading}
          onClick={() => removeMembership(account.id, m.id)}
          fill
          disabled={userId === m.user_id && !hasOtherAdmin}
        >
          {userId === m.user_id ? 'Leave' : 'Remove'}
        </Button>
      </>
    ),
  }));

  const invite_columns = [
    {
      id: 'email',
      text: 'Email',
      width: '55%',
      sorted: true,
    },
    {
      id: 'role',
      text: 'Role',
      width: '15%',
      render: (a: unknown) =>
        (a as string).replace(/./, (x) => x.toUpperCase()),
    },
    {
      id: 'status',
      text: 'Status',
      width: '15%',
      render: (a: unknown) =>
        (a as string).replace(/./, (x) => x.toUpperCase()),
    },
    {
      id: 'manage',
      text: 'Manage',
      width: '15%',
    },
  ];

  const pending_invites = account.invites.filter((x) => x.status === 'pending');

  const invite_data = pending_invites.map((i, index) => ({
    id: index,
    email: i.email,
    role: i.role,
    status: i.status,
    manage: (
      <>
        <Button
          loading={loading}
          onClick={() => removeInvite(account.id, i.id)}
          fill
        >
          Remove
        </Button>
      </>
    ),
  }));

  const inviteDisplayElem = (
    <div className="uk-margin">
      <Header
        className="uk-margin"
        level={4}
        style={{
          color: 'white',
          display: 'bock',
          float: 'left',
          alignItems: 'center',
        }}
      >
        <span style={{ padding: '0 0.5rem' }}>Invites</span>
      </Header>
      <Table
        columns={invite_columns}
        data={invite_data}
        style={{
          background: '#f18f01',
          overflowX: 'auto',
          width: '100%',
        }}
      />
    </div>
  );

  return (
    <>
      <div className="uk-margin">
        <Button loading={loading} onClick={onDone}>
          {'< Back'}
        </Button>
      </div>
      <Header
        className="uk-margin"
        level={4}
        style={{
          color: 'white',
          display: 'bock',
          float: 'right',
          alignItems: 'center',
        }}
      >
        <FaUserCircle />
        <span style={{ padding: '0 0.5rem' }}>License Usage:</span>
        {account.memberships.length}/{account.seats}
      </Header>
      <div className="uk-margin">
        <Header
          className="uk-margin"
          level={4}
          style={{
            color: 'white',
            display: 'bock',
            float: 'left',
            alignItems: 'center',
          }}
        >
          <span style={{ padding: '0 0.5rem' }}>Members</span>
        </Header>
        <Table
          columns={membership_columns}
          data={membership_data}
          style={{
            background: '#f18f01',
            overflowX: 'auto',
            width: '100%',
          }}
        />
      </div>
      {pending_invites.length > 0 && inviteDisplayElem}
      <div className="uk-margin">
        <Button
          loading={loading}
          onClick={toggleModal}
          disabled={
            account.memberships.length + pending_invites.length >= account.seats
          }
          style={{
            float: 'right',
          }}
        >
          + Invite User
        </Button>
      </div>
      <Modal
        cancel={toggleModal}
        footer={<div />}
        visible={modalVisible}
        data-testid="add-user-modal"
      >
        <Form
          onSubmit={(data) => {
            inviteMember(account.id, data);
          }}
        >
          <Form.Header>New User Details</Form.Header>
          <Form.Validator
            name="email"
            validators={[
              {
                validator: (value) => validateEmail(value),
                errorMessage: 'Email is not a valid format.',
              },
            ]}
            required
          >
            <Form.Input label="Email" data-testid="add-user-email" />
          </Form.Validator>
          <ButtonStyle>
            <Button type="submit" data-testid="add-user-confirm">
              Invite User
            </Button>
            <Button ghost onClick={toggleModal}>
              Cancel
            </Button>
          </ButtonStyle>
        </Form>
      </Modal>
    </>
  );
};

export default ManageAccount;
