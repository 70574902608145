import React, { useState } from 'react';
import { Button, Form, Typography } from 'amphitheatre';
import { useLocation } from 'react-router-dom';

import SuccessDisplay from './Success.display';
import callChange from './AcceptInviteNew.action';
import { toastError, assertIsError, validateEmail } from 'src/Utils';
import checkUsernameApi from '../Register/CheckUsername.action';

function AcceptInviteNew(): React.ReactElement {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const location = useLocation();
  const token = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1,
  );

  const acceptInviteNew = async (data: Record<string, unknown>) => {
    const { email, username, password } = data as {
      email: string;
      username: string;
      password: string;
    };

    setLoading(true);

    try {
      await callChange(email, username, password, token);
      setSuccess(true);
    } catch (error) {
      assertIsError(error);
      toastError(error.message);
    }

    setLoading(false);
  };

  if (success) {
    return <SuccessDisplay />;
  }

  return (
    <div>
      <Typography>
        Create an account to accept invite and join the team
      </Typography>
      <div style={{ padding: '0.5em' }} />
      <Form onSubmit={acceptInviteNew} clearOnSubmit>
        <Form.Validator
          name="email"
          validators={[
            {
              validator: (value) => validateEmail(value),
              errorMessage: 'Email is not a valid format.',
            },
          ]}
          required
        >
          <Form.Input label="Email" />
        </Form.Validator>
        <Form.Validator
          name="username"
          type="username"
          validators={[
            {
              validator: async (name) => await checkUsernameApi(name),
              errorMessage: 'Username already exists!',
            },
          ]}
          required
        >
          <Form.Input label="Username" />
        </Form.Validator>
        <Form.Validator name="password" type="password" required>
          <Form.Input label="Password" />
        </Form.Validator>
        <Button type="submit" loading={loading} fill>
          Create New Account
        </Button>
        <div className="uk-margin" />
      </Form>
    </div>
  );
}

export default AcceptInviteNew;
