import styled from 'styled-components';

const width = '40px';
const height = '20px';
const btnSize = '12px';

const colour = '#555';

export default styled.label`
  /*
  * Toggle Switch Element
  */

  /* The switch - the box around the slider */
  position: relative;
  display: inline-block;

  width: ${width};
  height: ${height};

  min-width: ${width};
  min-height: ${height};

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider {
    background-color: ${colour};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${colour};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(${height});
    -ms-transform: translateX(${height});
    transform: translateX(${height});
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: ${btnSize};
      width: ${btnSize};
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    /* Rounded sliders */
    &.round {
      border-radius: 34px;
    }

    &.round:before {
      border-radius: 50%;
    }
  }
`;
