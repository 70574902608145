import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Loader } from 'amphitheatre';

import { RootState } from '../../Redux/Reducers';

function mapStateToProps(state: RootState) {
  const { isEnterpriseMember, hasInvites } = state.user;
  const { activeSubscriptions } = state.license;

  return { isEnterpriseMember, hasInvites, activeSubscriptions };
}

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector>;

const StandingPage = ({
  hasInvites,
  isEnterpriseMember,
  activeSubscriptions,
}: Props): React.ReactElement => {
  if (
    hasInvites !== null &&
    isEnterpriseMember !== null &&
    activeSubscriptions !== null
  ) {
    if (isEnterpriseMember || hasInvites) {
      return <Redirect to="/teams" />;
    }
    return <Redirect to="/view-license" />;
  }

  return (
    <div className="uk-text-center">
      <Loader message="Loading your data..." />
    </div>
  );
};

export default connector(StandingPage);
