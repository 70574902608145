/* eslint-disable camelcase */
import jwtDecode from 'jwt-decode';
import store from '../Redux/store';

import { addAccessToken, deleteAllTokens } from '../Redux/Reducers/tokens';
import { setBrainTreeToken } from '../Redux/Reducers/user';

import { toastError } from './toast';
import { iamClient } from 'src/clients';

// In minutes
export const AUTO_REFRESH_THRESHOLD = 2;

interface Token {
  exp: number;
  fresh: boolean;
  iat: number;
  identity: string;
  jti: string;
  nbf: number;
  type: string;
}

export function refreshAccessToken(): void {
  iamClient
    .post('/refresh', {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      transformRequest: (data?: any, headers?: any) => {
        delete headers.common['Authorization'];
        return data;
      },
    })
    .then((res) => {
      if (res.status === 200) {
        const { access_token } = res.data;
        store.dispatch(addAccessToken(access_token));
      }
    })
    .catch(() => {
      // If there is any errors with the tokens, just delete it
      store.dispatch(deleteAllTokens());
    });
}

export function getBraintreeToken(): void {
  iamClient
    .post('/braintree_tokens')
    .then((res) => {
      if (res.data.braintree_token) {
        store.dispatch(setBrainTreeToken(res.data.braintree_token));
      }
    })
    .catch(() => {
      toastError('Unable to fetch BraintreeToken');
    });
}

export function checkAccessToken(): void {
  /*
    This function is used to validate the access token.
    If the token is near expiry, it will use the refresh token to update it.
    If it has expired, it will remove all the tokens.
   */
  const { accessToken } = store.getState().tokens;

  if (!accessToken) {
    return;
  }

  const decoded: Token = jwtDecode(accessToken);

  const expireDate = new Date(decoded.exp * 1000);
  const currentTime = new Date(Date.now());
  const refreshThreshold = new Date(Date.now());

  refreshThreshold.setMinutes(
    refreshThreshold.getMinutes() + AUTO_REFRESH_THRESHOLD,
  );

  if (currentTime >= expireDate) {
    // Token has expired. We are not going to auto refresh
    return;
  }

  if (refreshThreshold >= expireDate) {
    // We are nearly about to expire! The function `refreshAccessToken` is called using this method
    // to make mocking of the function in tests easier.
    exportModules.refreshAccessToken();
  }
}

export function getAccessExpiry(): number {
  const { accessToken } = store.getState().tokens;

  if (!accessToken) {
    return 0;
  }

  const decoded: Token = jwtDecode(accessToken);

  const expireDate = new Date(decoded.exp * 1000);
  const now = new Date(Date.now());

  return expireDate.getTime() - now.getTime();
}

const exportModules = {
  refreshAccessToken,
  getBraintreeToken,
  checkAccessToken,
  getAccessExpiry,
};

export default exportModules;
