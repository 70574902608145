import React, { useContext } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Select, { ValueType } from 'react-select';
import { Typography } from 'amphitheatre';
import { ThemeContext } from 'styled-components';

import { Gutter, StyledFilter, Wrapper } from './Filter.styled';
import { RootState } from '../../../Redux/Reducers';
import {
  setLicenseFilter,
  setLicenseSorter,
} from '../../../Redux/Reducers/licenseFilter';
import * as type from '../../../Redux/filterTypes';

const mapStateToProps = (state: RootState) => {
  const { filter, sorter } = state.licenseFilter;
  return { filter, sorter };
};

const connector = connect(mapStateToProps, {
  _setLicenseFilter: setLicenseFilter,
  _setLicenseSorter: setLicenseSorter,
});

type Props = ConnectedProps<typeof connector>;
type Option = { value: string; label: string };
const sortOptions: Option[] = [
  { value: type.SORT_PRICE_ASC, label: 'Billing Amount: Low to High' },
  { value: type.SORT_PRICE_DESC, label: 'Billing Amount: High to Low' },
  { value: type.SORT_PURCHASE_ASC, label: 'Earliest Purchase' },
  { value: type.SORT_PURCHASE_DESC, label: 'Latest Purchase' },
  { value: type.SORT_BILLING_ASC, label: 'Closest Bill Date' },
];

function Filter(props: Props) {
  const { filter, sorter, _setLicenseFilter, _setLicenseSorter } = props;

  const onFilter = (selected: ValueType<Option, false>) => {
    _setLicenseFilter(selected ? (selected as Option).value : null);
  };

  const onSort = (selected: ValueType<Option, false>) => {
    _setLicenseSorter(selected ? (selected as Option).value : null);
  };

  const filterOptions = [
    { value: type.FILTER_ACTIVE, label: 'Active' },
    { value: type.FILTER_EXPIRED, label: 'Expired' },
  ];

  const defaultFilter = filterOptions.filter((_) => _.value === filter)[0];

  const defaultSorter = sortOptions.filter((_) => _.value === sorter)[0];

  const themeContext = useContext(ThemeContext);

  return (
    <StyledFilter>
      <Wrapper>
        <Typography>Filter:</Typography>
        <Select
          defaultValue={defaultFilter}
          options={filterOptions}
          onChange={(value) => onFilter(value)}
          isClearable
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: themeContext.primary500 || '',
              primary25: themeContext.primary700 || '',
            },
          })}
          aria-label="filter"
        />
      </Wrapper>
      <Gutter />
      <Wrapper>
        <Typography>Sort:</Typography>
        <Select
          defaultValue={defaultSorter}
          options={sortOptions}
          onChange={(value) => onSort(value)}
          isClearable
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: themeContext.primary500 || '',
              primary25: themeContext.primary700 || '',
            },
          })}
          aria-label="sorter"
        />
      </Wrapper>
    </StyledFilter>
  );
}

export default connector(Filter);
