import { iamClient } from 'src/clients';
import { Invoice } from '../License/License.types';

export default async function (id: string): Promise<Invoice> {
  return new Promise<Invoice>((resolve, reject) =>
    iamClient
      .get(`/transactions/${id}`)
      .then((res) => {
        const invoice: Invoice = res.data;
        resolve(invoice);
      })
      .catch((err) => {
        reject(
          new Error(
            err?.response?.data?.msg ??
              'Unexpected error. Please try again or contact us.',
          ),
        );
      }),
  );
}
