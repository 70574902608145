import React from 'react';
import styled from 'styled-components';
import { Button, Typography } from 'amphitheatre';

import { WEBSITE_BASE_URL } from 'src/Components/App/AppGlobals';

const { Paragraph } = Typography;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;

  #info {
    margin: 0.5rem 0 1rem 0;
  }

  @media (min-width: 800px) {
    flex-flow: row;
    align-items: center;
    padding-bottom: 1rem;

    #info {
      margin: 0 0 0 1rem;
    }
  }
`;

function PIGIDownload(): JSX.Element {
  return (
    <Wrapper>
      <a href={`${WEBSITE_BASE_URL}/downloads`}>
        <Button>Download PIGI</Button>
      </a>
      <Paragraph id="info">
        <i>Windows (32bit & 64bit), Mac OS and Linux are all supported</i>
      </Paragraph>
    </Wrapper>
  );
}

export default PIGIDownload;
