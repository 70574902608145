import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Button, Form, Typography } from 'amphitheatre';

import { toastSuccess, toastError, assertIsError } from 'src/Utils';
import { changePassword } from './Users.actions';

const { EditText } = Typography;

function ChangePassword(): React.ReactElement {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  async function onSubmit(data: Record<string, unknown>) {
    const { newPasswordConfirm, currentPassword } = data as {
      newPasswordConfirm: string;
      currentPassword: string;
    };

    setLoading(true);

    try {
      if (newPassword === newPasswordConfirm) {
        const message = await changePassword(
          newPassword,
          newPasswordConfirm,
          currentPassword,
        );
        setEdit(false);
        toastSuccess(message);
      }
    } catch (error) {
      assertIsError(error);
      toastError(error.message);
    }

    setLoading(false);
  }

  const toggleEdit = () => setEdit(!edit);

  return (
    <div>
      <Typography>Password</Typography>
      <EditText onClick={toggleEdit}>••••••••••••••••••••••••</EditText>
      <AnimateHeight duration={250} height={edit ? 'auto' : 0}>
        <div style={{ padding: '0.5em' }} />
        <Form onSubmit={onSubmit} clearOnSubmit>
          <Form.Validator
            name="newPassword"
            type="password"
            peek={(_, value) => setNewPassword(value)}
            validators={[
              {
                validator: (value) =>
                  !value || !newPasswordConfirm || value === newPasswordConfirm,
                errorMessage: 'Passwords do not match',
              },
            ]}
            required
          >
            <Form.Input label="New Password" />
          </Form.Validator>

          <Form.Validator
            name="newPasswordConfirm"
            type="password"
            peek={(_, value) => setNewPasswordConfirm(value)}
            validators={[
              {
                validator: (value) =>
                  !value || !newPassword || value === newPassword,
                errorMessage: 'Passwords do not match',
              },
            ]}
            required
          >
            <Form.Input label="Confirm New Password" />
          </Form.Validator>

          <Form.Validator name="currentPassword" type="password" required>
            <Form.Input label="Current Password" />
          </Form.Validator>

          <Button type="submit" loading={loading} fill>
            Update Password
          </Button>
          <div className="uk-margin">
            <Button onClick={toggleEdit} disabled={loading} ghost fill>
              Cancel
            </Button>
          </div>
        </Form>
      </AnimateHeight>
    </div>
  );
}

export default ChangePassword;
