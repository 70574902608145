import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Subscription } from './license.type';

interface Plan {
  id: string;
  base_price: string;
  billing_frequency: number;
  description: string;
  name: string;
  price: string;
}

interface State {
  plans: Array<Plan> | null;
  activeSubscriptions: Array<Subscription> | null;
  expiredSubscriptions: Array<Subscription> | null;
}

export const initialState: State = {
  plans: null,
  activeSubscriptions: null,
  expiredSubscriptions: null,
};

const licenseSlice = createSlice({
  name: 'licenseManager',
  initialState,
  reducers: {
    setPlans: (state, action: PayloadAction<Array<Plan> | null>) => {
      const { payload } = action;
      state.plans = payload;
    },
    setSubscriptions: (
      state,
      action: PayloadAction<Array<Subscription> | null>,
    ) => {
      const { payload } = action;
      state.activeSubscriptions = payload;
    },
    setExpiredSubscriptions: (
      state,
      action: PayloadAction<Array<Subscription> | null>,
    ) => {
      const { payload } = action;
      state.expiredSubscriptions = payload;
    },
  },
});

export const { setPlans, setSubscriptions, setExpiredSubscriptions } =
  licenseSlice.actions;

export default licenseSlice.reducer;
