// Put complex filters in here. Otherwise just put it in the mapStateToProps function
import { RootState } from './Reducers';
import * as type from './filterTypes';

import { Subscription } from './Reducers/license.type';

export default (state: RootState): Subscription[] | null => {
  const { activeSubscriptions, expiredSubscriptions } = state.license;
  const { licenseFilter } = state;

  if (!activeSubscriptions || !expiredSubscriptions) {
    return null;
  }

  // Filter out the licenses

  let licenses: typeof activeSubscriptions = [];

  if (
    licenseFilter.filter === type.FILTER_ACTIVE ||
    licenseFilter.filter === null
  ) {
    licenses = [...activeSubscriptions];
  }

  if (
    licenseFilter.filter === type.FILTER_EXPIRED ||
    licenseFilter.filter === null
  ) {
    licenses = [...licenses, ...expiredSubscriptions];
  }

  // Now start sorting the license

  switch (licenseFilter.sorter) {
    case type.SORT_PURCHASE_ASC:
      licenses.sort((a, b) => {
        const dateA = new Date(a.start_date);
        const dateB = new Date(b.start_date);

        return dateA.getTime() - dateB.getTime();
      });
      break;
    case type.SORT_PURCHASE_DESC:
      licenses.sort((a, b) => {
        const dateA = new Date(a.start_date);
        const dateB = new Date(b.start_date);

        return dateB.getTime() - dateA.getTime();
      });
      break;
    case type.SORT_BILLING_DESC:
      licenses.sort((a, b) => {
        const dateA = new Date(a.next_billing_date);
        const dateB = new Date(b.next_billing_date);

        return dateB.getTime() - dateA.getTime();
      });
      break;
    case type.SORT_PRICE_ASC:
      licenses.sort((a, b) => {
        const costA = parseFloat(a.next_billing_amount);
        const costB = parseFloat(b.next_billing_amount);

        return costA - costB;
      });
      break;
    case type.SORT_PRICE_DESC:
      licenses.sort((a, b) => {
        const costA = parseFloat(a.next_billing_amount);
        const costB = parseFloat(b.next_billing_amount);

        return costB - costA;
      });
      break;
    default:
      break;
  }

  return licenses;
};
