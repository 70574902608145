import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from 'amphitheatre';
import * as Hero from 'src/page-styles/hero.styled';
import * as Base from 'src/page-styles/base.styled';

import { iamClient } from 'src/clients';

function ConfirmEmail(): React.ReactElement {
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const location = useLocation();
  const token = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1,
  );

  useEffect(() => {
    iamClient
      .post('/user/email/confirm', { token })
      .then((res) => {
        setMessage('Your email address has been updated.');
        setSuccess(true);
      })
      .catch((error) => {
        setMessage(
          error.response?.data?.msg ??
            'Unexpected error. Please try again or contact us.',
        );
        setSuccess(false);
      });
  }, [token]);

  let displayElement = <Loader message="Loading..." />;

  if (success) {
    displayElement = (
      <div>
        <Base.Header>Email Changed</Base.Header>
        <Base.Paragraph>{message}</Base.Paragraph>
      </div>
    );
  } else if (success === false) {
    displayElement = (
      <div>
        <Base.Header>Change Email Failed</Base.Header>
        <Base.Paragraph>{message}</Base.Paragraph>
      </div>
    );
  }

  return <Hero.Text style={{ margin: 0 }}>{displayElement}</Hero.Text>;
}

export default ConfirmEmail;
