import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface User {
  id: string | undefined;
  name: string;
  email: string;
  isEnterpriseMember: boolean | null;
  brainTreeToken: string | null;
  hasInvites: boolean | null;
}

export const initialState: User = {
  id: undefined,
  name: '',
  email: '',
  isEnterpriseMember: null,
  brainTreeToken: '',
  hasInvites: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.id = payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.name = payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.email = payload;
    },
    setBrainTreeToken: (state, action: PayloadAction<string | null>) => {
      const { payload } = action;
      state.brainTreeToken = payload;
    },
    setIsEnterpriseMember: (state, action: PayloadAction<boolean | null>) => {
      const { payload } = action;
      state.isEnterpriseMember = payload;
    },
    setHasInvites: (state, action: PayloadAction<boolean | null>) => {
      const { payload } = action;
      state.hasInvites = payload;
    },
  },
});

export const {
  setUserId,
  setName,
  setEmail,
  setBrainTreeToken,
  setIsEnterpriseMember,
  setHasInvites,
} = userSlice.actions;

export default userSlice.reducer;
