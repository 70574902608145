import { iamClient } from 'src/clients';

export default function (data: Record<string, unknown>): Promise<void> {
  return new Promise((resolve, reject) =>
    iamClient
      .post('/subscriptions', data)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            reject(
              new Error(
                'A mistake on our end has occurred, you have not been charged as a result.',
              ),
            );
          } else {
            reject(new Error(error.response.data.msg));
          }
        }

        reject(new Error('A mistake on our website has occurred.'));
      }),
  );
}
