import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from 'amphitheatre';

import { FiCheck } from 'react-icons/fi';
import { SuccessText } from '../App/App.styled';

const { Paragraph } = Typography;

interface Props {
  email: string;
}

const RegisterSuccess: React.FunctionComponent<Props> = ({ email }) => {
  return (
    <div className="uk-container uk-text-center uk-padding">
      <SuccessText>
        <FiCheck />
      </SuccessText>
      <div>
        <Paragraph>
          You have successfully registered. An email has been sent to&nbsp;
          {email}
        </Paragraph>
      </div>
      <Link to="/login">
        <Button fill>Return to login</Button>
      </Link>
    </div>
  );
};

export default RegisterSuccess;
