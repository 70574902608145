import styled from 'styled-components';

export const StyledFilter = styled.div`
  margin-bottom: 1em;
  display: flex;
`;

export const Wrapper = styled.div`
  flex-basis: 49%;
`;

export const Gutter = styled.div`
  flex-basis: 2%;
`;
