import React, { useState, useEffect } from 'react';
import { Container, Typography } from 'amphitheatre';

import MyMemberships from './MyMemberships';
import ManageAccount from './ManageAccount';
import InviteList from './Invites';
import { RootState } from '../../Redux/Reducers';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Membership, Memberships, Invite, Invites } from '../../Utils';
import { iamClient } from 'src/clients';

const { Header } = Typography;

function mapStateToProps(state: RootState) {
  const { isEnterpriseMember, hasInvites } = state.user;

  return { isEnterpriseMember, hasInvites };
}

const connector = connect(mapStateToProps, {});

export type MyTeamsProps = {
  userId: string;
} & ConnectedProps<typeof connector>;

function MyTeams({
  userId,
  isEnterpriseMember,
  hasInvites,
}: MyTeamsProps): JSX.Element {
  const [manageAccountId, setManageAccountId] = useState<string>();
  const [invites, setInvites] = useState<Invite[]>();
  const [adminMemberships, setAdminMemberships] = useState<Membership[]>();
  const [otherMemberships, setOtherMemberships] = useState<Membership[]>();

  const fetchMemberships = async () => {
    const {
      data: { memberships },
    } = await iamClient.get<Memberships>('/user/memberships');
    const filteredMemberships = memberships.filter(
      (x) =>
        x.account_type === 'enterprise' ||
        x.account_type === 'casualgroup' ||
        x.account_type === 'mission_1',
    );
    const membershipsByType = filteredMemberships.reduce(
      (acc: Record<'admin' | 'other', Membership[]>, m) => {
        const group = m.role === 'administrator' ? 'admin' : 'other';
        acc[group] = [...(acc[group] || []), m];
        return acc;
      },
      { admin: [], other: [] },
    );
    setAdminMemberships(membershipsByType['admin']);
    setOtherMemberships(membershipsByType['other']);
  };

  const fetchInvites = async () => {
    const {
      data: { invites },
    } = await iamClient.get<Invites>('/user/invites');
    setInvites(invites);
  };

  useEffect(() => {
    (async () => await fetchInvites())();
    (async () => await fetchMemberships())();
  }, []);

  let redirectBool =
    adminMemberships &&
    adminMemberships.length === 0 &&
    otherMemberships &&
    otherMemberships.length === 0 &&
    invites &&
    invites.length === 0;

  if (redirectBool) {
    return <Redirect to="/view-license" />;
  }

  const teamElement = (
    <div>
      <Header level={1}>Team Management</Header>
      {!manageAccountId ? (
        <MyMemberships
          adminMemberships={adminMemberships}
          otherMemberships={otherMemberships}
          fetchMemberships={() => fetchMemberships()}
          onManageAccount={(id: string) => setManageAccountId(id)}
        />
      ) : (
        <ManageAccount
          accountId={manageAccountId}
          userId={userId}
          onDone={() => setManageAccountId(undefined)}
        />
      )}
    </div>
  );

  const inviteElement = (
    <div>
      <Header level={1}>Pending Invites</Header>
      <InviteList
        invites={invites}
        fetchInvites={() => fetchInvites()}
        fetchInvitesMemberships={() => {
          fetchMemberships();
          fetchInvites();
          redirectBool =
            adminMemberships &&
            adminMemberships.length === 0 &&
            otherMemberships &&
            otherMemberships.length === 0 &&
            invites &&
            invites.length === 0;
        }}
      />
    </div>
  );

  return (
    <Container size="xlg">
      {((adminMemberships && adminMemberships.length > 0) ||
        (otherMemberships && otherMemberships.length > 0)) &&
        teamElement}
      {invites && invites.length > 0 && inviteElement}
    </Container>
  );
}

export default connector(MyTeams);
