/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import axios from 'axios';
import { Button, Container, Loader, Typography } from 'amphitheatre';
import { toastError, Membership, getIsEnterpriseMember } from '../../Utils';
import { iamClient } from 'src/clients';
import { TeamManagementRow } from './MyTeams.styled';
const { Header } = Typography;

export type MyMembershipListProps = {
  adminMemberships?: Membership[];
  otherMemberships?: Membership[];
  fetchMemberships: () => void;
  onManageAccount?: (id: string) => void;
};

const MyMembershipList = ({
  adminMemberships,
  otherMemberships,
  fetchMemberships,
  onManageAccount,
}: MyMembershipListProps): JSX.Element => {
  const manageAccountForMembership = async (id: string) => {
    const membership = adminMemberships?.find((m) => m.id === id);
    if (membership && onManageAccount) onManageAccount(membership.account_id);
  };

  const deleteMembership = async (id: string) => {
    const membership = otherMemberships?.find((m) => m.id === id);
    if (
      membership &&
      window.confirm(
        `Are you sure you want to leave ${membership.account_name}?`,
      )
    ) {
      try {
        await iamClient.delete(`/memberships/${id}`);
        await fetchMemberships();
        getIsEnterpriseMember();
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data.msg) {
          toastError(error.response.data.msg);
        } else {
          toastError('API call to leave team failed.');
        }
      }
    }
  };

  if (!adminMemberships?.length && !otherMemberships?.length)
    return (
      <div key="loader" className="uk-text-center">
        <Loader message="Loading Teams ..." />
      </div>
    );

  return (
    <>
      {adminMemberships && adminMemberships.length > 0 && (
        <Container style={{ marginBottom: '1em' }} border size="sm">
          <Header level={4}>Teams you manage</Header>
          {adminMemberships?.map((membership) => (
            <MyMembershipItem
              key={membership.id}
              id={membership.id}
              accountName={membership.account_name}
              accountType={membership.account_type}
              buttonLabel={'Manage'}
              onButtonClick={manageAccountForMembership}
            />
          ))}
        </Container>
      )}
      {otherMemberships && otherMemberships.length > 0 && (
        <Container style={{ marginBottom: '1em' }} border size="sm">
          <Header level={4}>Teams you are a member of</Header>
          {otherMemberships?.map((membership) => (
            <MyMembershipItem
              key={membership.id}
              id={membership.id}
              accountName={membership.account_name}
              accountType={membership.account_type}
              buttonLabel={'Leave'}
              onButtonClick={deleteMembership}
            />
          ))}
        </Container>
      )}
    </>
  );
};

export type MyMembershipItemProps = {
  id: string;
  accountName: string;
  accountType: string;
  buttonLabel: 'Manage' | 'Leave';
  onButtonClick: (id: string) => Promise<void>;
};

const MyMembershipItem = ({
  id,
  accountName,
  accountType,
  buttonLabel,
  onButtonClick,
}: MyMembershipItemProps): JSX.Element => {
  const handleButtonClick = async (id: string) => {
    await onButtonClick(id);
  };

  return (
    <TeamManagementRow key={id} className="uk-margin">
      <span>{`${accountName} (${accountType})`}</span>
      <Button
        style={{ marginLeft: 'auto' }}
        onClick={async () => await handleButtonClick(id)}
      >
        {buttonLabel}
      </Button>
    </TeamManagementRow>
  );
};

export default MyMembershipList;
