import React from 'react';
import { Button, Form, PhoneInput } from 'amphitheatre';
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';

import { validateNumber } from '../../../Utils';

import ButtonStyle, { ButtonText, FormWrapper } from './PurchaseForm.styled';
import BuyColumn from '../BuyColumn';

interface Props {
  /** Sends information entered by the user to the parent */
  getData: (data: Record<string, unknown>) => void;

  /** Inform the parent to go back to the previous screen */
  goback: () => void;

  /** Inform the parent to go to the next screen */
  proceed: () => void;

  /** Remember the form data */
  rememberForm: (data: Record<string, unknown>) => void;

  /** Id of the plan selected */
  selectedPlan: string;

  initialData: Record<string, unknown>;
}

interface State {
  formPeek: Record<string, unknown>;
}

class PurchaseForm extends React.Component<Props, State> {
  state = {
    formPeek: {},
  };

  static defaultProps = {
    initialData: {
      billingCompany: '',
      billingFirstName: '',
      billingLastName: '',
      billingStreetAddress: '',
      billingExtStreetAddress: '',
      billingCity: '',
      billingState: '',
      billingCountry: '',
      billingPostalCode: '',
      customerPhone: '',
    },
    selectedPlan: undefined,
  };

  onSubmit = (data: Record<string, unknown>): void => {
    const { getData, proceed } = this.props;
    getData(data);
    proceed();
  };

  remember = (name: string, value: string): void => {
    const { formPeek } = this.state;
    this.setState({ formPeek: { ...formPeek, [name]: value } });
  };

  componentWillUnmount = (): void => {
    const { rememberForm } = this.props;
    const { formPeek } = this.state;
    rememberForm(formPeek);
  };

  render(): React.ReactElement {
    const { goback, initialData, selectedPlan } = this.props;

    return (
      <FormWrapper>
        <BuyColumn selectedPlan={selectedPlan} />
        <Form id="form" onSubmit={this.onSubmit}>
          <Form.Header>Contact Details</Form.Header>

          <Form.Validator
            defaultValue={initialData.billingCompany as string}
            name="company"
            peek={this.remember}
          >
            <Form.Input label="Company" />
          </Form.Validator>

          <Form.Validator
            defaultValue={initialData.billingFirstName as string}
            name="first_name"
            peek={this.remember}
            required
          >
            <Form.Input label="First Name" />
          </Form.Validator>
          <Form.Validator
            defaultValue={initialData.billingLastName as string}
            name="last_name"
            peek={this.remember}
            required
          >
            <Form.Input label="Last Name" />
          </Form.Validator>
          <Form.Validator
            defaultValue={initialData.customerPhone as string}
            name="phone"
            peek={this.remember}
          >
            <PhoneInput defaultCountry="AU" name="phone" label="Phone" />
          </Form.Validator>

          <Form.Header>Address Information</Form.Header>

          <Form.Validator
            defaultValue={initialData.billingStreetAddress as string}
            name="billing_street_address"
            peek={this.remember}
            required
          >
            <Form.Input label="Address Line 1" />
          </Form.Validator>

          <Form.Validator
            defaultValue={initialData.billingExtStreetAddress as string}
            name="billing_ext_address"
            peek={this.remember}
          >
            <Form.Input label="Address Line 2" />
          </Form.Validator>

          <Form.Validator
            defaultValue={initialData.billingCity as string}
            name="billing_city"
            peek={this.remember}
            required
          >
            <Form.Input label="City" />
          </Form.Validator>
          <Form.Validator
            defaultValue={initialData.billingState as string}
            name="billing_state"
            peek={this.remember}
            required
          >
            <Form.Input label="State" />
          </Form.Validator>
          <Form.Validator
            defaultValue={initialData.billingPostalCode as string}
            name="billing_post_code"
            validators={[
              {
                validator: (value) => validateNumber(value),
                errorMessage: 'You need to enter a valid postcode.',
              },
            ]}
            type="number"
            peek={this.remember}
            required
          >
            <Form.Input label="Postcode" />
          </Form.Validator>
          <Form.Validator
            defaultValue={initialData.billingCountry as string}
            name="billing_country"
            peek={this.remember}
            required
          >
            <Form.Input label="Country" />
          </Form.Validator>
          <ButtonStyle className="uk-margin">
            <Button type="submit">
              <ButtonText>
                Continue To Purchase
                <FiChevronsRight />
              </ButtonText>
            </Button>
            <Button type="button" ghost onClick={goback}>
              <ButtonText>
                <FiChevronsLeft />
                Go Back
              </ButtonText>
            </Button>
          </ButtonStyle>
        </Form>
      </FormWrapper>
    );
  }
}

export default PurchaseForm;
