/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import axios from 'axios';
import { Button, Container, Typography } from 'amphitheatre';
import { toastError, Invite } from '../../Utils';
import { iamClient } from 'src/clients';
import { TeamManagementRow } from './MyTeams.styled';

const { Header } = Typography;

export type InviteListProps = {
  invites?: Invite[];
  fetchInvites: () => void;
  fetchInvitesMemberships: () => void;
};

const InviteList = ({
  invites,
  fetchInvites,
  fetchInvitesMemberships,
}: InviteListProps): JSX.Element => {
  const acceptInvite = async (id: string) => {
    try {
      await iamClient.post(`/invites/${id}/accept`);
      fetchInvitesMemberships();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data.msg) {
        toastError(error.response.data.msg);
      } else {
        toastError('API call to accept invite failed.');
      }
    }
  };

  const declineInvite = async (id: string) => {
    try {
      await iamClient.post(`/invites/${id}/decline`);
      fetchInvites();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data.msg) {
        toastError(error.response.data.msg);
      } else {
        toastError('API call to decline invite failed.');
      }
    }
  };

  return (
    <>
      <Container style={{ marginBottom: '1em' }} border size="sm">
        <Header level={4}>Teams you've been invited to</Header>
        {invites?.map((invite) => (
          <InviteItem
            key={invite.id}
            id={invite.id}
            accountName={invite.account_name}
            acceptButtonLabel={'Accept'}
            onAcceptButtonClick={acceptInvite}
            declineButtonLabel={'Decline'}
            onDeclineButtonClick={declineInvite}
          />
        ))}
      </Container>
    </>
  );
};

export type InviteItemProps = {
  id: string;
  accountName: string;
  acceptButtonLabel: 'Accept';
  onAcceptButtonClick: (id: string) => Promise<void>;
  declineButtonLabel: 'Decline';
  onDeclineButtonClick: (id: string) => Promise<void>;
};

const InviteItem = ({
  id,
  accountName,
  acceptButtonLabel,
  onAcceptButtonClick,
  declineButtonLabel,
  onDeclineButtonClick,
}: InviteItemProps): JSX.Element => {
  const handleAcceptButtonClick = async (id: string) => {
    await onAcceptButtonClick(id);
  };

  const handleDeclineButtonClick = async (id: string) => {
    await onDeclineButtonClick(id);
  };

  return (
    <TeamManagementRow key={id} className="uk-margin">
      <span>{accountName}</span>
      <Button
        style={{ marginLeft: 'auto' }}
        onClick={async () => await handleAcceptButtonClick(id)}
      >
        Accept
      </Button>
      <Button
        style={{ marginLeft: '1em' }}
        onClick={async () => await handleDeclineButtonClick(id)}
      >
        Decline
      </Button>
    </TeamManagementRow>
  );
};

export default InviteList;
