/* eslint-disable camelcase */
import { iamClient } from 'src/clients';
import store from '../../../Redux/store';
import { addAccessToken } from '../../../Redux/Reducers/tokens';

export function changePassword(
  newPassword: string,
  newPasswordConfirm: string,
  currentPassword: string,
): Promise<string> {
  return new Promise((resolve, reject) =>
    iamClient
      .post('/password', {
        new_password: newPassword,
        confirm_new_password: newPasswordConfirm,
        password: currentPassword,
      })
      .then((res) => {
        const { access_token } = res.data;
        store.dispatch(addAccessToken(access_token));
        resolve('Successfully changed password!');
      })
      .catch((error) => {
        reject(
          new Error(
            error?.response?.data?.msg ??
              'Unexpected error. Please try again or contact us.',
          ),
        );
      }),
  );
}

export function changeEmail(newEmail: string): Promise<string> {
  return new Promise((resolve, reject) =>
    iamClient
      .post('/user/email', { email: newEmail })
      .then((res) => {
        resolve('Check your email to confirm your update!');
      })
      .catch((error) => {
        reject(
          new Error(
            error?.response?.data?.msg ??
              'Unexpected error. Please try again or contact us.',
          ),
        );
      }),
  );
}

export function changeUsername(newUsername: string): Promise<string> {
  return new Promise((resolve, reject) =>
    iamClient
      .post('/user/username', { username: newUsername })
      .then((res) => {
        const { access_token } = res.data;
        store.dispatch(addAccessToken(access_token));
        resolve('Successfully changed username!');
      })
      .catch((error) => {
        reject(
          new Error(
            error?.response?.data?.msg ??
              'Unexpected error. Please try again or contact us.',
          ),
        );
      }),
  );
}
