import React from 'react';
import { iamClient } from 'src/clients';
import { connect, ConnectedProps } from 'react-redux';
import {
  Button,
  Container,
  Loader,
  Modal,
  Typography,
  Steps,
} from 'amphitheatre';

import { RootState } from 'src/Redux/Reducers';
import selectLicense from 'src/Redux/selectors';
import Filter from './Filter';
import Subscription from './License';
import PIGIDownload from './PIGIDownload';
import { clearMyPurchases, getMyPurchases, toastError } from 'src/Utils';
import SelectPlan from './SelectPlan';
import PurchaseForm from './PurchaseForm';
import Braintree from './Braintree';
import PaymentSuccessful from './PaymentSuccessful';

const NUM_OF_STEPS = 3;

const { Header, Paragraph } = Typography;

function mapStateToProps(state: RootState) {
  const { accessToken } = state.tokens;
  const { activeSubscriptions, expiredSubscriptions } = state.license;
  const subscriptions = selectLicense(state);
  return {
    accessToken,
    subscriptions,
    activeSubscriptions,
    expiredSubscriptions,
  };
}

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector>;

interface Progress {
  progress: number;
}
interface State {
  cancelVisible: boolean;
  subscriptionId: string;

  selectedPlan: string;
  selectedCost: string;
  progress: number;
  purchaseData: Record<string, unknown> | undefined;
}

class ViewLicenses extends React.Component<Props, State> {
  state: State = {
    cancelVisible: false,
    subscriptionId: '',

    selectedPlan: '',
    selectedCost: '',
    progress: 0,
    purchaseData: undefined,
  };

  select = (id: string, price: string): void =>
    this.setState({ selectedPlan: id, selectedCost: price });

  proceed = (): void =>
    this.setState(({ progress }: Progress) => ({
      progress: Math.min(progress + 1, NUM_OF_STEPS),
    }));

  goback = (): void =>
    this.setState(({ progress }: Progress) => ({
      progress: Math.max(progress - 1, 0),
    }));

  reset = (): void =>
    this.setState({ progress: 0, selectedPlan: '', selectedCost: '' });

  getPurchaseData = (purchaseData: Record<string, unknown>): void => {
    const { selectedCost, selectedPlan } = this.state;

    const newPurchaseData = {
      ...purchaseData,
      cost: selectedCost,
      plan_id: selectedPlan,
    };

    this.setState({ purchaseData: newPurchaseData });
  };

  rememberForm = (newData: Record<string, unknown>): void => {
    const { purchaseData } = this.state;

    const newPurchaseData = { ...purchaseData, ...newData };

    this.setState({ purchaseData: newPurchaseData });
  };

  setProgress = (progress: number): void => this.setState({ progress });

  cancelSubscription = (id: string) =>
    this.setState({
      cancelVisible: true,
      subscriptionId: id,
    });

  confirmCancel = () => {
    const { subscriptionId } = this.state;

    this.setState({ cancelVisible: false });
    clearMyPurchases();

    iamClient
      .post(`/subscriptions/${subscriptionId}/cancel`, {
        bt_subscription_id: subscriptionId,
      })
      .then(() => {
        getMyPurchases();
      })
      .catch((error) => {
        if (error.response) {
          toastError(error.response.data.msg);
        } else {
          toastError('Call to api failed for some reason!');
        }
      });
  };

  closeCancel = () => this.setState({ cancelVisible: false });

  render(): JSX.Element {
    const { cancelVisible, selectedPlan, progress, purchaseData } = this.state;
    const {
      accessToken,
      subscriptions,
      activeSubscriptions,
      expiredSubscriptions,
    } = this.props;

    if (!subscriptions) {
      return (
        <div className="uk-text-center">
          <Loader message="Loading Purchased Licenses ..." />
        </div>
      );
    }

    const cancelFooter = (
      <div className="uk-text-right">
        <Button style={{ marginRight: '0.75em' }} onClick={this.closeCancel}>
          No
        </Button>
        <Button ghost onClick={this.confirmCancel}>
          Yes
        </Button>
      </div>
    );

    let purchasedLicensesDisplayElement = null;

    let buyLicenseDisplayElement = (
      <SelectPlan
        selectedPlan={selectedPlan}
        select={this.select}
        proceed={this.proceed}
      />
    );

    if (progress === 1) {
      buyLicenseDisplayElement = (
        <PurchaseForm
          initialData={purchaseData || {}}
          getData={this.getPurchaseData}
          goback={this.goback}
          proceed={this.proceed}
          rememberForm={this.rememberForm}
          selectedPlan={selectedPlan}
        />
      );
    } else if (progress === 2) {
      buyLicenseDisplayElement = (
        <Braintree
          goback={this.goback}
          proceed={this.proceed}
          purchaseData={purchaseData || {}}
        />
      );
    } else if (progress === 3) {
      buyLicenseDisplayElement = <PaymentSuccessful reset={this.reset} />;
    }

    const licenseCards = subscriptions.map((subscription) => {
      if (subscription.status === 'Active') {
        return (
          <Subscription
            key={subscription.bt_subscription_id}
            id={subscription.bt_subscription_id}
            accessToken={accessToken || ''}
            title={subscription.plan}
            purchaseDate={subscription.start_date}
            billingDate={subscription.next_billing_date}
            billingAmount={subscription.next_billing_amount}
            expiryDate={subscription.paid_through_date}
            status={'Active'}
            cancel={this.cancelSubscription}
            data-testid="license-active"
          />
        );
      } else if (subscription.status === 'Canceled') {
        return (
          <Subscription
            key={subscription.bt_subscription_id}
            id={subscription.bt_subscription_id}
            accessToken={accessToken || ''}
            title={subscription.plan}
            purchaseDate={subscription.start_date}
            expiryDate={subscription.paid_through_date}
            status={'Cancelled'}
            cancelled
            data-testid="license-cancelled"
          />
        );
      } else {
        return (
          <Subscription
            key={subscription.bt_subscription_id}
            id={subscription.bt_subscription_id}
            accessToken={accessToken || ''}
            title={subscription.plan}
            purchaseDate={subscription.start_date}
            expiryDate={subscription.paid_through_date}
            status={'Expired'}
            cancelled
            expired
            data-testid="license-inactive"
          />
        );
      }
    });

    purchasedLicensesDisplayElement = (
      <div>{licenseCards.length ? licenseCards : null}</div>
    );

    const hasNoSubscriptions =
      (expiredSubscriptions === null || expiredSubscriptions.length === 0) &&
      (activeSubscriptions === null || activeSubscriptions.length === 0);
    const hasExpiredSubscriptions =
      expiredSubscriptions && expiredSubscriptions.length > 0;
    const hasActiveSubscriptions =
      activeSubscriptions && activeSubscriptions.length > 0;

    const buyLicenseContainerElement = (
      <div className="uk-margin">
        <Header level={1}>Buy License</Header>
        <Steps progress={progress}>
          <Steps.Item
            description="Select Plan"
            onClick={() => this.setProgress(0)}
          />
          <Steps.Item
            description="Enter Details"
            onClick={() => this.setProgress(1)}
          />
          <Steps.Item description="Pay" />
        </Steps>
        <div className="uk-margin">{buyLicenseDisplayElement}</div>
      </div>
    );

    return (
      <Container size="xlg">
        {hasNoSubscriptions ||
        (hasExpiredSubscriptions && !hasActiveSubscriptions)
          ? buyLicenseContainerElement
          : null}

        {hasActiveSubscriptions || hasExpiredSubscriptions ? (
          <div className="uk-margin">
            <Header level={1}>Purchased Licenses</Header>
            {hasActiveSubscriptions ? (
              <div>
                {' '}
                <PIGIDownload />{' '}
              </div>
            ) : null}
            <Filter />
            {purchasedLicensesDisplayElement}
          </div>
        ) : null}

        <Modal
          cancel={this.closeCancel}
          footer={cancelFooter}
          visible={cancelVisible}
        >
          <Header>Cancel Subscription</Header>
          <Paragraph>Do you want to cancel your subscription?</Paragraph>
        </Modal>
      </Container>
    );
  }
}

export default connector(ViewLicenses);
