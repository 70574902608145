/* eslint-disable no-useless-escape */
// Ignore this eslint rule because it triggers for the email regex and
// I do not want to touch the regex because I ripped it from
// stackoverflow.

export function validateEmail(email: string): boolean {
  if (!email) {
    return true;
  }
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateNumber(number: string): boolean {
  if (!number) {
    return true;
  }

  const re = /^[0-9\b]+$/;
  return re.test(number);
}
