import React from 'react';
import { Container } from 'amphitheatre';

import { Route, Switch, Redirect } from 'react-router-dom';

import Login from '../Login';
import Register from '../Register';
import ForgotPassword from '../ForgotPassword';
import ConfirmEmail from '../ConfirmEmail';
import ConfirmUser from '../ConfirmUser';
import ResendConfirmation from '../ResendConfirmation';
import ResetPassword from '../ResetPassword';

import UserManager from '../UserManager';
import Teams from '../Teams';
import ViewLicenses from '../ViewLicenses';
import InvoiceComponent from '../ViewLicenses/InvoicePage';

import StandingPage from '../StandingPage';
import AcceptInviteNew from '../AcceptInviteNew';
import AcceptInviteExisting from '../AcceptInviteExisting';

export const LoginRoutes = (
  <Container border size="xs">
    <Switch>
      <Route path="/confirm_email" component={ConfirmEmail} />
      <Route path="/confirm_user" component={ConfirmUser} />
      <Route path="/resend_confirmation" component={ResendConfirmation} />
      <Route path="/reset_password" component={ResetPassword} />
      <Route path="/register" component={Register} />
      <Route path="/reset" component={ForgotPassword} />
      <Route path="/login" component={Login} />
      <Route path="/accept_invite_new" component={AcceptInviteNew} />
      <Route path="/accept_invite_existing" component={AcceptInviteExisting} />
      <Route path="*" render={() => <Redirect to="/login" />} />
    </Switch>
  </Container>
);

export const MainRoutes = (userId: string): JSX.Element => (
  <>
    <Switch>
      <Route path="/confirm_email" component={ConfirmEmail} />
      <Route path="/invoice/:invoice_id" component={InvoiceComponent} />
      <Route path="/confirm_user" component={ConfirmUser} />
      <Route path="/reset_password" component={ResetPassword} />
      <Route path="/view-license" component={ViewLicenses} />
      <Route path="/user" component={UserManager} />
      <Route path="/teams">
        <Teams userId={userId} />
      </Route>
      <Route path="*" component={StandingPage} />
    </Switch>
  </>
);
