/* eslint-disable camelcase */
import { iamClient } from 'src/clients';

export class UnconfirmedError extends Error {}

export function callLoginApi(data: Record<string, unknown>): Promise<string> {
  const { username, password } = data;

  return new Promise<string>((resolve, reject) =>
    iamClient
      .post('/login', { username, password })
      .then((res) => {
        const { access_token } = res.data;
        resolve(access_token);
      })
      .catch((err) => {
        if (err?.response?.data?.code === 'USER_EXISTS_UNCONFIRMED') {
          reject(new UnconfirmedError());
        } else {
          reject(
            new Error(
              err?.response?.data?.msg ??
                'Unexpected error. Please try again or contact us.',
            ),
          );
        }
      }),
  );
}
