import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/Reducers';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'amphitheatre';
import { ThemeProvider } from 'styled-components';
import { FiLogOut } from 'react-icons/fi';

import SaberLight from '../../Themes/SaberLight';
import SaberDark from '../../Themes/SaberDark';

import {
  addAccessToken,
  deleteAllTokens,
  preserveToken,
} from '../../Redux/Reducers/tokens';

import { setDarkMode, toggleDarkMode } from '../../Redux/Reducers/darkMode';

import {
  getIsEnterpriseMember,
  getHasInvites,
  getAccessExpiry,
  getUserDetail,
  getPlans,
  getMyPurchases,
  getTokenHistory,
  clearData,
} from '../../Utils';

import { ACCESS_TOKEN, WEBSITE_BASE_URL } from './AppGlobals';

import { LoginRoutes, MainRoutes } from './AppRoutes';
import AppIdle from './AppIdle';
import RefreshModal from '../RefreshModal';
import ToggleSwitch from '../ToggleSwitch';
import callLogoutApi from './Logout.action';

import { BodyStyle, HeaderSpacer } from './App.styled';

import Logo from './logo.png';

function mapStateToProps(state: RootState) {
  const { darkMode } = state;
  const { accessToken } = state.tokens;
  const { id: userId, isEnterpriseMember, hasInvites } = state.user;
  return {
    userId,
    accessToken,
    darkMode,
    isEnterpriseMember,
    hasInvites,
  };
}

const connector = connect(mapStateToProps, {
  _addAccessToken: addAccessToken,
  _deleteAllTokens: deleteAllTokens,
  _setDarkMode: setDarkMode,
  _preserveToken: preserveToken,
  _toggleDarkMode: toggleDarkMode,
});

type AppProps = ConnectedProps<typeof connector>;

const NewApp = ({
  _addAccessToken,
  _deleteAllTokens,
  _setDarkMode,
  _preserveToken,
  _toggleDarkMode,
  accessToken,
  darkMode,
  isEnterpriseMember,
  hasInvites,
  userId,
}: AppProps): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (accessToken) return;
    const isLocal: boolean = localStorage.getItem(ACCESS_TOKEN) ? true : false;

    const storedAccessToken =
      localStorage.getItem(ACCESS_TOKEN) ??
      sessionStorage.getItem(ACCESS_TOKEN);
    if (storedAccessToken) {
      _addAccessToken(storedAccessToken);
    }

    const storedDarkMode = localStorage.getItem('darkMode');
    if (storedDarkMode !== null) {
      _setDarkMode(storedDarkMode === 'true');
    }

    _preserveToken(isLocal);
  }, [_addAccessToken, _setDarkMode, _preserveToken, accessToken]);

  useEffect(() => {
    if (accessToken) {
      if (getAccessExpiry() <= 0) {
        _deleteAllTokens();
        return;
      }
      getIsEnterpriseMember();
      getHasInvites();
      getUserDetail();
      getPlans();
      getMyPurchases();
      getTokenHistory();
    } else {
      clearData();
    }
  }, [accessToken, _deleteAllTokens]);

  async function revokeTokens() {
    await callLogoutApi();
  }

  let renderElm = LoginRoutes;
  let loggedInMenu: React.ReactElement | null = null;

  if (accessToken && userId) {
    renderElm = MainRoutes(userId);
    loggedInMenu = (
      <>
        {(hasInvites || isEnterpriseMember) && (
          <Menu.Item to="teams">Teams</Menu.Item>
        )}
        <Menu.Item to="view-license">My Licenses</Menu.Item>
        <Menu.Item to="user">My Details</Menu.Item>
        <Menu.Divider />
        <Menu.Item to="logout">
          Sign out&nbsp;&nbsp;
          <FiLogOut />
        </Menu.Item>
      </>
    );
  }

  return (
    <ThemeProvider theme={darkMode ? SaberDark : SaberLight}>
      <Menu
        activeId={location.pathname.substr(1)}
        cover
        onMenuClick={(selected) => {
          if (selected === 'toggleDarkMode') {
            _toggleDarkMode();
            return;
          }

          const to = `/${selected}`;
          if (location.pathname !== to && selected !== 'logout') {
            history.push(to);
          }
          if (selected === 'logout') {
            revokeTokens();
            _deleteAllTokens();
            history.push('/');
          }
        }}
        imgObj={
          <a
            href={WEBSITE_BASE_URL}
            rel="noopener noreferrer"
            target="_blank"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <img
              src={Logo}
              style={{ height: '32px', padding: '1rem 0' }}
              alt="Saber Astronautics"
            />
          </a>
        }
        shadow
        role="navigation"
      >
        {loggedInMenu}
        <Menu.Item to="toggleDarkMode" data-testid="dark-mode">
          <span className="uk-margin-small-right">Dark Mode</span>
          <ToggleSwitch ariaLabel="Darkmode Toggle" checked={darkMode} />
        </Menu.Item>
      </Menu>
      <HeaderSpacer />
      <main>{renderElm}</main>
      <footer>
        <RefreshModal />
      </footer>
      <AppIdle />
      <BodyStyle />
    </ThemeProvider>
  );
};

export default connector(NewApp);
