/* eslint-disable camelcase */
// Disabled due to how backend represents data

import React from 'react';
import { Button, Typography } from 'amphitheatre';
import BuyLogo from './launch.svg';

import {
  Content,
  DollarSign,
  PriceBar,
  Price,
  OldPrice,
  Logo,
  StyledBuyCard,
} from './BuyCard.styled';

const { Header, Paragraph } = Typography;

interface Props {
  data: Array<{
    base_price: string;
    billing_frequency: number;
    description: string;
    id: string;
    name: string;
    price: string;
  }>;

  onSelect: (id: string, price: string) => void;

  selectedPlan?: string;
}

interface State {
  selected: string | undefined;
}

class BuyCard extends React.Component<Props, State> {
  state: State = { selected: undefined };

  static defaultProps = {
    data: [],
    onSelect: undefined,
    selectedPlan: undefined,
  };

  componentDidMount = (): void => {
    const { selectedPlan } = this.props;
    this.select(selectedPlan || 'pigi_casual_3month');
  };

  select = (id: string): void => {
    const { data, onSelect } = this.props;
    let price = '0';

    this.setState({ selected: id });

    data.some((license) => {
      if (license.id === id) {
        price = license.price;
        return true;
      }
      return false;
    });

    if (onSelect) {
      onSelect(id, price);
    }
  };

  onClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const { id } = e.target as HTMLButtonElement;
    this.select(id);
  };

  render(): React.ReactElement {
    const { data } = this.props;
    const { selected } = this.state;
    let oldPrice = 0;
    let newPrice = 0;

    data.some((license) => {
      const { id, billing_frequency, base_price, price } = license;
      if (id === selected) {
        if (billing_frequency !== 1) {
          oldPrice = billing_frequency * parseInt(base_price, 10);
        }
        newPrice = parseInt(price, 10);
        return true;
      }
      return false;
    });

    return (
      <StyledBuyCard>
        <Logo src={BuyLogo} alt="PIGI" />
        <Content>
          <Header>PIGI Casual</Header>
          <Paragraph>
            P.I.G.I. is modern age mission control software. Its video-game
            interface is easy to use and easy to train but rich enough to see
            any part of a spacecraft. Large constellations, SSA, and dish
            control are embedded in the same tool.
          </Paragraph>
          <Paragraph style={{ fontWeight: 'bold' }}>Billing Cycle</Paragraph>
          <Button.Group>
            <Button
              id="pigi_casual_1month"
              ghost={selected !== 'pigi_casual_1month'}
              onClick={this.onClick}
            >
              Monthly
            </Button>
            <Button
              id="pigi_casual_3month"
              ghost={selected !== 'pigi_casual_3month'}
              onClick={this.onClick}
            >
              Quarterly
            </Button>
            <Button
              id="pigi_casual_12month"
              ghost={selected !== 'pigi_casual_12month'}
              onClick={this.onClick}
            >
              Annually
            </Button>
          </Button.Group>
        </Content>
        <PriceBar>
          <Price>
            <DollarSign>$</DollarSign>
            {newPrice}
            <DollarSign>AUD</DollarSign>
          </Price>
          {oldPrice ? (
            <OldPrice>
              <DollarSign>$</DollarSign>
              {oldPrice}
              <DollarSign>AUD</DollarSign>
            </OldPrice>
          ) : null}
        </PriceBar>
      </StyledBuyCard>
    );
  }
}

export default BuyCard;
