import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-flow: column;

  button:first-child {
    margin-bottom: 1.5em;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-flow: row-reverse;

  #form {
    flex-grow: 1;
  }
`;

export const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TeamManagementRow = styled.div`
  display: flex;
  color: white;
  align-items: center;
  font-family: Poppins;
`;
