import React, { useState } from 'react';
import { MdChevronRight } from 'react-icons/md';
import { Chevron, THContent, StyledTable, Wrapper } from './Table.styled';

export interface Column {
  /** The id of the data we are extracting from */
  id: string;

  /** Visible name for the table */
  text: React.ReactNode;

  /** Render out the value of the data */
  render?: (data: unknown) => React.ReactNode;

  /** Width out of 100 */
  width?: string;

  /** Should this column be sorted */
  sorted?: boolean;

  /** Function to sort the values */
  sorter?: (a: string, b: string) => number;
}

export type Data = {
  id: string | number;
  style?: React.CSSProperties;
} & Record<string, unknown>;

interface Props extends React.HTMLAttributes<HTMLTableElement> {
  columns: Array<Column>;

  data: Array<Data> | undefined;
}

const pointer = {
  cursor: 'pointer',
};

/**
 * Creates a table using the columns and data parameters.
 *
 * Note: The order of the column is determined by the order of the columns
 */
function Table({ children, columns, data, ...otherProps }: Props) {
  const [sortId, setSortId] = useState('');
  const [up, setUp] = useState(false);

  if (!columns || !data) {
    return null;
  }

  function toggleSort(id: string) {
    if (id === sortId) {
      setUp(!up);
    } else {
      setUp(false);
      setSortId(id);
    }
  }

  const headers = (
    <thead>
      <tr>
        {columns.map((column) => {
          const chevron = (
            <Chevron
              className={
                sortId === column.id
                  ? up
                    ? 'selected-up'
                    : 'selected-down'
                  : undefined
              }
              id={column.id}
              key={column.id}
            >
              <MdChevronRight />
            </Chevron>
          );

          return (
            <th key={column.id} style={{ width: column.width || 'auto' }}>
              <THContent
                style={column.sorted ? pointer : undefined}
                onClick={
                  column.sorted ? () => toggleSort(column.id) : undefined
                }
                data-testid={`column-${column.id}`}
              >
                {column.text}
                {column.sorted ? chevron : undefined}
              </THContent>
            </th>
          );
        })}
      </tr>
    </thead>
  );

  const rows: JSX.Element[] = [];

  const sortedData = [...data];
  const selectedCol = columns.find((value) => value.id === sortId);

  sortedData.sort((a, b) => {
    const flip = up ? -1 : 1;
    const aValue = a[sortId] as string;
    const bValue = b[sortId] as string;

    if (selectedCol?.sorter) {
      return selectedCol.sorter(aValue, bValue) * flip;
    }

    // Default string sorter where we sort by the first character

    if (aValue < bValue) return -1 * flip;
    else if (aValue > bValue) return 1 * flip;
    return 0;
  });

  sortedData.forEach((row) => {
    // Construct the row
    const rowCols = columns.map((col) => {
      if (col.render) return <td key={col.id}>{col.render(row[col.id])}</td>;
      return <td key={col.id}>{row[col.id] as React.ReactNode}</td>;
    });
    rows.push(
      <tr key={row.id} style={row.style}>
        {rowCols}
      </tr>,
    );
  });

  const body = <tbody>{rows}</tbody>;

  return (
    <Wrapper {...otherProps}>
      <StyledTable>
        {headers}
        {body}
      </StyledTable>
    </Wrapper>
  );
}

export default Table;
