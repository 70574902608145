import { iamClient } from 'src/clients';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ACCESS_TOKEN } from '../../Components/App/AppGlobals';

interface State {
  accessToken: string | null;
  preserveToken: boolean;
}

export const initialState: State = {
  accessToken: null,
  preserveToken: false,
};

const tokenSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    preserveToken: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      state.preserveToken = payload;
    },
    addAccessToken: (state, action: PayloadAction<string>) => {
      const { preserveToken } = state;
      const { payload } = action;
      iamClient.defaults.headers.common = {
        Authorization: `Bearer ${payload}`,
      };
      if (preserveToken) {
        localStorage.setItem(ACCESS_TOKEN, payload);
      } else {
        sessionStorage.setItem(ACCESS_TOKEN, payload);
      }

      state.accessToken = payload;
    },
    deleteAllTokens: (state) => {
      localStorage.removeItem(ACCESS_TOKEN);
      sessionStorage.removeItem(ACCESS_TOKEN);
      state.accessToken = null;
      delete iamClient.defaults.headers.common['Authorization'];
    },
  },
});

export const { addAccessToken, deleteAllTokens, preserveToken } =
  tokenSlice.actions;

export default tokenSlice.reducer;
