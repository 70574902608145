import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Typography } from 'amphitheatre';

import { toastError, validateEmail, assertIsError } from 'src/Utils';

import RegisterSuccess from './RegisterSuccess';
import callRegisterApi from './Register.action';
import checkUsernameApi from './CheckUsername.action';

const { Header } = Typography;

function Register(): React.ReactElement {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);

  const onRegister = async (data: Record<string, unknown>) => {
    const { email } = data;

    setSubmitted(true);
    setEmail(email as string);

    try {
      await callRegisterApi(data);
      setSuccess(true);
    } catch (error) {
      assertIsError(error);
      toastError(error.message);
    }

    setSubmitted(false);
  };

  if (success) {
    return <RegisterSuccess email={email} />;
  }

  return (
    <div className="uk-margin-top">
      <Form onSubmit={onRegister}>
        <span className="uk-text-center">
          <Header level={2}>Create new user</Header>
        </span>
        <Form.Validator
          name="email"
          validators={[
            {
              validator: (value) => validateEmail(value),
              errorMessage: 'Email is not a valid format.',
            },
          ]}
          required
        >
          <Form.Input label="Email" />
        </Form.Validator>
        <Form.Validator
          name="username"
          validators={[
            {
              validator: async (name) => await checkUsernameApi(name),
              errorMessage: 'Username already exists!',
            },
          ]}
          required
        >
          <Form.Input label="Username" showErrorOnChange showLoadingIcon />
        </Form.Validator>
        <Form.Validator name="password" type="password" required>
          <Form.Input label="Password" />
        </Form.Validator>
        <Button type="submit" fill loading={submitted}>
          Create New Account
        </Button>
      </Form>
      <div className="uk-margin">
        <Link to="/login">
          <Button ghost fill disabled={submitted}>
            Return to login
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default Register;
