import React from 'react';
import styled from 'styled-components';

import Users from './Users';
import TokenManager from './TokenManager';

const Wrapper = styled.div`
  margin: auto;

  @media (min-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 1rem;
    margin: 0 1rem;
  }

  @media (min-width: 1300px) {
    margin: auto;
    max-width: 1300px;
  }
`;

export default function (): JSX.Element {
  return (
    <Wrapper>
      <TokenManager />
      <Users />
    </Wrapper>
  );
}
