import React from 'react';
import ReactDOM from 'react-dom';
import BraintreeWebDropIn from 'braintree-web-drop-in';

import {
  Options as BraintreeOptions,
  Dropin,
  PaymentMethodRequestablePayload,
  PaymentOptionSelectedPayload,
} from 'braintree-web-drop-in';

type Options = Omit<BraintreeOptions, 'container'>;

interface Props {
  options: Options;
  preselectVaultedPaymentMethod?: boolean;

  onInstance?: (instance: Dropin) => void;
  onError?: (error: unknown) => void;

  onNoPaymentMethodRequestable?: (...arg: unknown[]) => void;
  onPaymentMethodRequestable?: (
    payload: PaymentMethodRequestablePayload,
  ) => void;
  onPaymentOptionSelected?: (payload: PaymentOptionSelectedPayload) => void;
}

export default class DropIn extends React.Component<Props> {
  wrapper: HTMLDivElement | null = null;
  instance: Dropin | undefined;

  async componentDidMount(): Promise<void> {
    try {
      this.instance = await BraintreeWebDropIn.create({
        container: ReactDOM.findDOMNode(this.wrapper) as HTMLElement,
        preselectVaultedPaymentMethod: this.props.preselectVaultedPaymentMethod,
        ...this.props.options,
      });

      this.instance.on('noPaymentMethodRequestable', (...args: unknown[]) => {
        if (this.props.onNoPaymentMethodRequestable) {
          this.props.onNoPaymentMethodRequestable(...args);
        }
      });
      this.instance.on(
        'paymentMethodRequestable',
        (payload: PaymentMethodRequestablePayload) => {
          if (this.props.onPaymentMethodRequestable) {
            this.props.onPaymentMethodRequestable(payload);
          }
        },
      );
      this.instance.on(
        'paymentOptionSelected',
        (payload: PaymentOptionSelectedPayload) => {
          if (this.props.onPaymentOptionSelected) {
            this.props.onPaymentOptionSelected(payload);
          }
        },
      );

      if (this.props.onInstance) {
        this.props.onInstance(this.instance);
      }
    } catch (error) {
      if (this.props.onError) {
        this.props.onError(error);
      }
    }
  }

  async componentWillUnmount(): Promise<void> {
    if (this.instance) {
      await this.instance.teardown();
    }
  }

  shouldComponentUpdate(): boolean {
    // Static
    return false;
  }

  render(): JSX.Element {
    return <div ref={(ref) => (this.wrapper = ref)} />;
  }
}
