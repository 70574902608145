import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Form, Loader, Typography } from 'amphitheatre';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/Redux/Reducers';

import Token from './Token';

const { Header, Paragraph: P } = Typography;

const CustomContainer = styled.div`
  align-self: flex-start;
  grid-column-start: 1;
  grid-column-end: span 2;
  margin: 0;
  padding: 1em 2em;

  @media (min-width: 1100px) {
    border: 1px solid grey;
    border-radius: 10px;
  }
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.grey500};
  height: 1px;
  width: 100%;
`;

const Tokens = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1rem;
`;

function mapStateToProps(state: RootState) {
  const { tokens } = state.tokenHistory;
  return { tokens };
}

const connector = connect(mapStateToProps, {});
type Props = ConnectedProps<typeof connector>;

function TokenManager({ tokens }: Props): JSX.Element {
  const [hide, setHide] = useState(false);

  const accessTokens = tokens?.filter((token) => {
    const { expires, revoked, type } = token;
    const expire = moment(parseInt(expires));
    const expired =
      moment().isAfter(expire) || revoked.toLowerCase() === 'true';

    return !(expired && hide) && type === 'Access';
  });

  const refreshTokens = tokens?.filter((token) => {
    const { expires, revoked, type } = token;
    const expire = moment(parseInt(expires));
    const expired =
      moment().isAfter(expire) || revoked.toLowerCase() === 'true';

    return !(expired && hide) && type === 'Refresh';
  });

  return (
    <CustomContainer>
      <Header level={1}>Token Manager</Header>
      <Form.Checkbox
        label="Hide revoked tokens: "
        value={hide ? 'true' : 'false'}
        onClick={() => setHide(!hide)}
      />
      {tokens ? (
        <>
          <Header level={3}>Access Tokens</Header>
          <Tokens>
            {accessTokens?.length ? (
              accessTokens?.map((info, index) => (
                <React.Fragment key={`grouping-${index}`}>
                  {index ? <Divider key={`divider-${index}`} /> : null}
                  <Token key={`token-${index}`} {...info} />
                </React.Fragment>
              ))
            ) : (
              <P>There is currently no history of your access tokens!</P>
            )}
          </Tokens>
          <br />
          <Header level={3}>Refresh Tokens</Header>
          <Tokens>
            {refreshTokens?.length ? (
              refreshTokens?.map((info, index) => (
                <React.Fragment key={`grouping-${index}`}>
                  {index ? <Divider key={`divider-${index}`} /> : null}
                  <Token key={`token-${index}`} {...info} />
                </React.Fragment>
              ))
            ) : (
              <P>There is currently no history of your refresh tokens!</P>
            )}
          </Tokens>
        </>
      ) : (
        <div className="uk-text-center">
          <Loader message="Loading Token data ..." />
        </div>
      )}
    </CustomContainer>
  );
}

export default connector(TokenManager);
