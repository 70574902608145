import { iamClient } from 'src/clients';

export default async function (
  newPassword: string,
  newPasswordConfirm: string,
  token: string | null,
): Promise<void> {
  await iamClient
    .post('/password/reset/confirm', {
      new_password: newPassword,
      confirm_new_password: newPasswordConfirm,
      token: token,
    })
    .catch((err) =>
      Promise.reject(
        new Error(
          err?.response?.data?.msg ??
            'Unexpected error. Please try again or contact us.',
        ),
      ),
    );
}
