import { Themes } from 'amphitheatre';

import SaberTheme from './theme.type';

const SaberDark: SaberTheme = {
  ...Themes.saberLight,
  bg: 'rgb(33, 37, 41)',

  btnGhost: Themes.saberLight.white,
  btnGhostHover: Themes.saberLight.grey300,
  btnLink: '#669fca',

  editorBg: '#ffffff',
  editText: '#6c757d',

  fontColour: 'white',

  formGroupInput: 'white',
  formGroupBorder: 'rgba(255, 255, 255, 0.15)',
  formGroupBg: 'rgba(255, 255, 255, 0.075)',
  formGroupFocusGlow: Themes.saberLight.primary800,
  formHeaderText: 'white',

  grey100: '#f8f9fa',
  grey200: '#e9ecef',
  grey300: '#dee2e6',
  grey400: '#ced4da',
  grey500: '#adb5bd',
  grey600: '#6c757d',
  grey700: '#495057',
  grey800: '#343a40',
  grey900: '#212529',

  loaderSpinBg: 'rgba(255, 255, 255, 0.15)',
  loaderTextColour: 'white',

  licenseBg: 'rgb(52, 58, 64)',
  licenseBgDisabled: 'rgb(108, 117, 125)',
  licenseBgDisabledBorder: 'rgb(73, 80, 87)',
  licenseShadow:
    '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',

  menuBgColour: 'rgb(52, 58, 64)',
  menuShadow: 'rgb(31, 31, 31) 5px 5px 10px, rgb(37, 37, 37) -5px -5px 10px',

  modalBg: '#212529',
  modalCloseBg: '#212529',
  modalCloseText: '#ffffff',

  phoneInputBorder: 'rgba(255, 255, 255, 0.15)',
  phoneInputText: 'white',

  stepsDisabledColour: '#495057',
  stepsTextColour: 'white',

  tableHeaderText: '#e6eff6',
};

export default SaberDark;
