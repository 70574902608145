import styled from 'styled-components';
import SaberLight from '../../../Themes/SaberLight';

const ICON_SIZE = '32px';

export const Block = styled.div`
  display: flex;
  align-items: center;

  padding-bottom: 0;
  padding-right: 2em;
`;

export const ActionBlock = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const Icon = styled.div`
  border: 2px solid ${({ theme }) => theme.grey500};
  border-radius: 50%;

  color: ${({ theme }) => theme.grey500};

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  max-width: ${ICON_SIZE};
  max-height: ${ICON_SIZE};
  min-width: ${ICON_SIZE};
  min-height: ${ICON_SIZE};

  margin-right: 1em;
`;

export const IconText = styled.div`
  display: inline-block;

  span {
    display: block;
    color: ${({ theme }) => theme.fontColour};
  }

  .title {
    font-size: 1rem;
  }

  .content {
    font-size: 1rem;
    font-weight: bold;
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.fontColour};
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 1.25rem;
  font-weight: bold;
`;

export const Content = styled.div`
  display: flex;

  padding: 0.5em;

  align-items: center;
  flex-flow: row;
`;

export const StyledLicense = styled.div`
  box-shadow: ${({ theme }) => theme.licenseShadow};
  background-color: ${({ theme }) => theme.licenseBg};
  border-left: 10px solid ${({ theme }) => theme.primary400};

  margin-bottom: 1em;
  padding: 0.5em;

  &.cancelled {
    background-color: ${({ theme }) => theme.licenseBgDisabled};
    border-left: 10px solid ${({ theme }) => theme.licenseBgDisabledBorder};
  }
`;
StyledLicense.defaultProps = {
  theme: {
    licenseBg: SaberLight.licenseBg,
    licenseBgDisabled: SaberLight.licenseBgDisabled,
    licenseBgDisabledBorder: SaberLight.licenseBgDisabledBorder,
    licenseShadow: SaberLight.licenseShadow,
  },
};
